<template id="app">
  <router-view></router-view>
</template>

<script>
export default {
  name: "LayoutDefault",

  setup() {
    return {};
  },
};
</script>

<style>
td {
  font-size: 11px !important;
}

/* tamaños */
.xlarge {
  font-size: 40px;
}

.large {
  font-size: 30px;
}

.medium {
  font-size: 25px;
}

.middle {
  font-size: 18px;
}

.normal {
  font-size: 15px;
}

.small {
  font-size: 10px;
}

/* grosores */
.thin {
  font-weight: 400;
}

.fit {
  font-weight: 600;
}

.fat {
  font-weight: 800;
}

</style>