import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from '../main'


const authUtils = {}

authUtils.login = async function (email, pass) {
    return new Promise(resolve => {
        signInWithEmailAndPassword(auth, email, pass).then(rta => {
                    resolve(rta)
        }).catch(function(error) {
            if(error.message == 'Firebase: Error (auth/invalid-email).' ){
                window.alert('Error de formato en el email')
            }if(error.message == 'Firebase: Error (auth/user-not-found).'){
                window.alert('Usuario no registrado');
            }if(error.message == 'Firebase: Error (auth/wrong-password).'){
                window.alert('Contraseña errada');
            }else{
                window.alert(error.message);
            }
        })
    });
}

authUtils.singup = async function (email, pass) {
    return new Promise(resolve => {
        createUserWithEmailAndPassword(auth, email, pass).then(rta => {
            resolve(rta)
        }).catch(function(error) {
            // console.log(error.message)
           if(error.message == 'Firebase: Password should be at least 6 characters (auth/weak-password).' ){
                window.alert('La contrasela debe tener al menos 6 caracteres')
            }if(error.message == 'Firebase: Error (auth/invalid-email).'){
                window.alert('Error de formato en el email');
            }if(error.message == 'Firebase: Error (auth/email-already-in-use)'){
                window.alert('Correo ya resgitrado')
            }
            else{
                window.alert(error.message);
            }
        })
    })
}


authUtils.out = function () {
    signOut(auth)
}

export  {authUtils}