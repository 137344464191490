<template>
    <div class="row">
      <div class="col-6">
        <h4 class="text-subtitle1 q-ma-none text-primary">
          Datos generales del domiciliario:
        </h4>
        <span class="text-subtitle1 column">
          Nombre del domiciliario:
          {{ info["nombres"] + " " + info["apellidos"] }}
        </span>
        <div>
          <span class="text-subtitle1"> Estado de disponibilidad: </span>
          <q-icon
            size="xs"
            :color="info['disponible'] == true ? 'green' : 'red'"
            name="circle"
          >
          </q-icon>
        </div>
        <div>
          <span class="text-subtitle1"> Confianza: </span>
          <q-chip
            clickable
            text-color="white"
            @click="changeData(info, 'confianza', !info.confianza  )"
            :color="info['confianza'] == true ? 'orange' : 'red'"
            dense
            square
            class="text-weight-bolder"
          >{{ info["confianza"] ? "SI" : "No" }}
          </q-chip>
        </div>
        <div class="row">
          <span class="text-subtitle1 column"> Estado: </span>
          <q-chip
            clickable
            :color="info['activo'] ? 'green' : 'red'"
            text-color="white"
            @click="changeData(info, 'activo', !info.activo  )"
            dense
            class="text-weight-bolder"
            square
            >{{ info["activo"] ? "Activo" : "Inactivo" }}</q-chip
          >
        </div>
        <span class="text-subtitle1">
          Fecha de ingreso:
          {{ $filters.timeComplete(info["fechaIngreso"]) }}
        </span>
        <h4 class="text-subtitle1 text-primary column">Datos de contacto:</h4>
        <span class="text-subtitle1 column">
          Nombre de contacto: {{ info["nombreContacto"] }}
        </span>
        <span class="text-subtitle1 column">
          Numero de contacto: {{ info["numeroContacto"] }}
        </span>
        <span class="text-subtitle1 column">
          Dirección de vivienda: {{ info["direccionVivienda"] }}
        </span>
        <span class="text-subtitle1 column">
          Versión app: {{ info["versionApp"] }}
        </span>
        <span class="text-subtitle1 column">
          Maximo de servicios: {{ info["max_srvs"] }}
        </span>
      </div>
      <!-- <div class="col-6">
        <span class="text-subtitle1 text-primary column">
          Vincular cuenta:
        </span>
        <q-select
            :dense="true"
            use-input
            hide-selected
            fill-input
            v-model="listas.toChange"
            @filter="fetchOptionsDomi"
            @input-value="(value) =>updateSearchAll(value)"
            prepend-icon="search"
            append-icon="close"
            input-debounce="300"
            :options="listas.backupDomiciliarios"
            option-label="nombres"
            option-value="id"
            label="Asignar domiciliarío"
          >
          <template v-slot:after>
              <q-icon
                name="save"
                @click="dialogV = true"
                class="cursor-pointer q-ml-md"
              >
              </q-icon>
            </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.nombres  }}</q-item-label>
                <q-item-label>{{ scope.opt.info.numeroContacto == 0 ? '' : scope.opt.info.numeroContacto   }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Sin resultados
                </q-item-section>
              </q-item>
            </template>
          </q-select>
      </div> -->
    </div>
    
</template>


<script>

import { reactive } from "@vue/reactivity";
import { host, userUtils } from '@/tools/index';
import axios from "axios";
import { useQuasar } from "quasar";


export default {
    props: {
        domi: Object,
  },
  emits: ["close-popup-domiciliario"],
    
  setup(props, {emit}) { 
      const info = reactive(props.domi)
      // const dialog = ref(false);
      // const dialogV = ref(false);
      const $q = useQuasar();
      
      const listas = reactive({
        listaDomiciliarios: [],
        toChange: '',
        backupDomiciliarios: [],
      })
      
     getLista();

      function getLista() {
        userUtils.getToken().then(token => {
          const fd = new FormData();
          fd.append('token', token)
          axios.post(host + 'listaDomiciliarios', fd).then(result => {
            var data = result.data.rta;
            console.log(listas.listaDomiciliarios)
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              listas.listaDomiciliarios.push({
                nombres: element["nombres"] + " " + element["apellidos"],
                id: element["id"],
                info: element,
              });
            }
            listas.backupDomiciliarios = listas.listaDomiciliarios;
          })
        })  
      }

      function fetchOptionsDomi(val, update) {
        update(() => {
        const needle = val.toLowerCase();
        listas.backupDomiciliarios = listas.listaDomiciliarios.filter(
          (v) => {
            if (v.nombres == '') {
            return  v.numeroContacto.indexOf(needle) > -1
            } else {
              return v.nombres.toLocaleLowerCase().indexOf(needle) > -1
            }
          }
        );
      });
      }
    function changeData(a, name, dato) {
      userUtils.getToken().then((token) => {
        const fd = {
          [name]: dato,
        }
         let headers = {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: token,
        };
        axios
          .patch(a.url ,fd,  {headers: headers})
          .then((result) => {
            let data = result.status
            if (data === 200) {
              $q.notify({
                type: "positive",
                message: `Se ajustó el ${name} de ${a.nombres} a ${dato}`,
                timeout: 2000,
              });
              emit('close-popup-domiciliario', true)
            }
          })
          .catch((error) => {
            var msj = error.response.data.detail;
            $q.notify({ type: "negative", message: msj, timeout: 1500 });
          });
      });
    }

    function updateSearchAll(a) {
      listas.toChange = a;
    } 

    function vincular() {
      if (listas.toChange.id == info.id) {
              $q.notify({ type: "negative", message: 'Se seleccionó el mismo usuario que se esta editando' });
      } else {
        userUtils.getToken().then(token => {
          const fd = new FormData();
          fd.append('token', token);
          fd.append('oldId', listas.toChange.id);
          fd.append('newId', info.id);
          axios.post(host + "migraDomiciliario", fd).then(result => {
            console.log(result.data.rta)
            if (result.data.rta == 'OK') {
              emit('close-popup-domiciliario', true)
              $q.notify({ type: "positive", message: 'Se vincularon las cuentas correctamente' });
            }
          }).catch((error) => {
            $q.notify({ type: "negative", message: error.response.data.rta });
          });
        })
      }
    }
        
        return {
          info,
          listas,
          fetchOptionsDomi, 
          updateSearchAll,
          vincular,
          changeData
        }
    }
}

</script>