<template>
    <q-card class="bg-white text-black">
        <q-card-section>
            <q-input
                label="Nombre de la base"
                dense
                v-model="baseInformation.name"
            >
            </q-input>
                <q-input
                label="Rango de cobertura"
                dense
                v-model="baseInformation.rango"
            >
            </q-input>
            <div class="col-4 q-pa-md" style="height: 400px; width: 400px;"  >
                <!--  porque no sirves -->
                <l-map :minZoom="3" :maxZoom="18" @click="changeMarker" :zoom="mapa.zoom" :center="mapa.center">
                    <l-tile-layer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    ></l-tile-layer>
                    <l-control-layers position="topright"></l-control-layers>
                    <l-marker  :lat-lng="mapa.posMapa" :draggable="true"></l-marker>
                </l-map>
            </div>
      </q-card-section>
       <q-card-actions>
        <q-space></q-space>
        <q-btn flat color="grey" @click="close" label="Cerrar"></q-btn>
        <q-btn flat color="primary" @click="saveNewBase" label="Guardar"></q-btn>
      </q-card-actions>

    </q-card>
</template>

<script>
import {  reactive  } from "vue";
import {
  LMap,
  LMarker,
  LTileLayer,
    LControlLayers,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import {  host, userUtils } from '@/tools';
import axios from 'axios';
import { useQuasar } from 'quasar';



export default {
    emits: ['closePopupNewBase'],
    components: {
    LMap,
    LMarker,
    LTileLayer,
    LControlLayers,
},
    setup(props, { emit }) {
        const $q = useQuasar();

      
        const baseInformation = reactive({
            name: null,
            rango: null,
        })

        const mapa = reactive({
            zoom: 10,
            center: [7.121113247309666,-73.11342428633715],
            // mostrarMap: false,
            posMapa: [7.121113247309666,-73.11342428633715],
        });
    
        function changeMarker(a) {
            mapa.posMapa = a.latlng
        }
        function close() {
            emit('closePopupNewBase', true)
        }

        function saveNewBase(){
            userUtils.getToken().then((token) => {
                const fd = new FormData();
                fd.append('token',  token );
                fd.append('nombre',  baseInformation.name);
                fd.append('lat',  mapa.posMapa.lat );
                fd.append('lon',   mapa.posMapa.lng);
                fd.append('radio',  baseInformation.rango);
                axios.post(`${host}nuevaBase`, fd).then((rta) => {
                    console.log(rta)
                    if(rta.data.rta == 'OK'){
                        close()
                    }else{
                        $q.notify({ type: "negative", message: rta.data.rta, timeout: 1500 });
                    }
                })
                }).catch((error) => { 
                    console.log(error)
                    var msj = error.response.data;
                    $q.notify({ type: "negative", message: msj, timeout: 1500 });
            })
        }

        return {
            close,
            changeMarker,
            mapa, 
            saveNewBase, 
            baseInformation
        }
    }
}

</script>
