import axios from "axios";
import {userUtils, host} from "./index";



const configUtils = {}


configUtils.getIndicadores  = async function () {
  return new Promise((resolve, reject) => {
    userUtils.getToken().then((token) => {
      const fd = new FormData();
      fd.append("token", token);
      axios
        .post(host + "indicadoresGenerales", fd)
        .then((result) => {
              resolve(result.data.rta);
        }).catch((error) => {
            reject(error)
          });
      });
    });
  }


  configUtils.getConfigs  = async function () {
    return new Promise((resolve, reject) => {
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("tipo", 'mostrar_todas');
        fd.append("config", 'ALL');
        axios
          .post(host + "configuraciones", fd)
          .then((result) => {
            if (result.data.rta == 'OK') {
              resolve(result.data.lista);
            }
           }).catch((error) => {
             var msj = error.response.data.rta;
             reject(msj)
          });
      });
    });
    }

 configUtils.getServiciosConcurrentes  = async function () {
  return new Promise((resolve,reject) => {
    userUtils.getToken().then((token) => {
      const fd = new FormData();
      fd.append("token", token);
      fd.append("tipo", "get");
      fd.append("config", "SRVS_CONCURRENTES");
      axios
        .post(host + "configuraciones", fd)
        .then((result) => {
          if (result.data.rta) {
            resolve(result.data.rta)
          } else {
            reject(result.data.rta)
          } 
         }).catch((error) => {
           var msj = error.response.data.rta;
           reject(msj)
          });
    });
  });
  }

  configUtils.setVariable = async function (type,  cantidad) {
    return new Promise((resolve,reject) => {
      userUtils.getToken().then((token) => {
      const fd = new FormData();
      fd.append("token", token);
      fd.append("tipo", "set_cantidad");
      fd.append("config", type);
      fd.append("cantidad", cantidad);
      axios
        .post(host + "configuraciones", fd)
        .then((result) => {
          if (!result.data.rta) {
            reject(result.data.rta);
          } if (result.data.rta == 'OK') {
            resolve(cantidad);
          }
        })
        .catch((error) => {
          var msj = error.response.data.rta;
          reject(msj);
          });
    });
  });
  }


  export  {configUtils}