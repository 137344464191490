import { getAuth } from 'firebase/auth'
import {host} from './variables.js'
import axios from 'axios'


const userUtils = {}

 userUtils.getToken = async function(){
    return new Promise(resolve => {
        getAuth().onAuthStateChanged(user =>{
            // console.log(user.accessToken)
            if(user){
            user.getIdToken(/* forceRefresh */).then((idToken) => {
                if (idToken) {
                    // console.log(idToken)
                    resolve(idToken)
                }
            });
        }
        })
    })
}


userUtils.refreshToken = async function(){
    return new Promise(resolve => {
        getAuth().onAuthStateChanged(user =>{
            if(user){
                user.getIdToken(/* forceRefresh */ true).then((rToken) => {
                    if (rToken) {
                        // console.log('renovar el token')
                        // console.log(rToken)
                        resolve(rToken);
                    }
                });
            }
        })
    })
}

userUtils.valida = async function (token){
    return new Promise(resolve => {
        const fd = new FormData();
            fd.append("token", token);
            axios.post(host + 'validaUsuario', fd).then(data => {
                if (data.data.data == 'OK') {
                    resolve(true);
                }
                else {
                    resolve(false)
                }
            }).catch(error => {
                resolve(false)
            window.alert(error);

          });
        
        resolve(true)
    })
}


export  {userUtils}