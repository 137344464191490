<template>
  <div class="q-ma-sm">
    <!-- Datos del cliente -->
    <div class="row">
      <!-- La divicion entre la info y el mapa  -->
      <div class="col-7">
        <div class="row">
          <div class="col-12 q-ma-none">
            <span class="text-subtitle1 q-mr-md"> Datos del cliente </span>
            <q-chip
              size="sm"
              clickable
              :color="cliente.confianza ? 'green' : 'grey'"
              text-color="white"
              @click="cliente.confianza = !cliente.confianza"
              class="text-weight-bolder"
              square
              >{{
                cliente.confianza
                  ? "Domicilio de confianza"
                  : "Domicilio normal"
              }}</q-chip
            >
          </div>
          <div class="col-6">
            <q-select
              :dense="true"
              use-input
              @filter="fetchOptions"
              @keyup.enter="changeToE"
              @keydown.tab.prevent="changeToE"
              @update:model-value="(value) => updateSearchAll(value, 'cliente')"
              :loading="listas.loading"
              input-debounce="400"
              :options="listas.clientes"
              option-label="nombres"
              option-value="id"
              label="Buscar (por nombre o tel) / Crear (nombre) "
            >
              <template v-slot:append>
                <q-icon
                  size="xs"
                  v-if="cliente.nombre !== ''"
                  name="close"
                  @click="cliente.nombre = ''"
                  class="cursor-pointer"
                >
                </q-icon>
              </template>
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-italic text-grey">
                    Sin resultados
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="col-6 q-px-sm">
            <q-input
              v-model="cliente.nombre"
              :dense="true"
              label="Nombre cliente *"
            >
              <template v-slot:append>
                <q-icon
                  size="xs"
                  v-if="cliente.nombre !== ''"
                  name="close"
                  @click="clear('')"
                  class="cursor-pointer"
                >
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-6">
            <q-input
              :dense="true"
              v-show="inputTel"
              ref="inputTelefono"
              v-model="cliente.tel"
              label="Teléfono cliente"
            >
              <template v-slot:append>
                <q-icon
                  size="xs"
                  v-if="cliente.tel !== ''"
                  name="close"
                  @click="cliente.tel = ''"
                  class="cursor-pointer"
                ></q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-6 q-px-sm">
            <q-input
              :dense="true"
              v-show="inputTel"
              v-model="cliente.whatsapp"
              label="WhatsApp cliente"
            >
              <template v-slot:append>
                <q-icon
                  size="xs"
                  v-if="cliente.whatsapp !== ''"
                  name="close"
                  @click="cliente.whatsapp = ''"
                  class="cursor-pointer"
                ></q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <!-- información recogida  -->
        <span class="text-subtitle1"> Dirección recogida</span>
        <q-icon
          v-if="listas.direcciones.length != 0"
          class="q-pl-xs"
          name="img:/icons/change.svg"
          size="xs"
          @click="changeDirecciones"
        ></q-icon>
        <div v-if="listas.direcciones.length != 0" class="col-12">
          <q-select
            use-input
            :dense="true"
            v-show="inputDir"
            ref="inputDirecciones"
            v-model="cliente.direccionId"
            :options="listas.direcciones"
            @filter="fetchDirecRegister"
            @update:model-value="(value) => direccionGeo(value)"
            option-label="direccion"
            option-value="id"
            label="Direcciones registradas"
            :autofocus="true"
          >
            <template v-slot:append>
              <q-icon
                size="xs"
                v-if="cliente.direccionId != ''"
                name="close"
                @click="noRegistradas"
                class="cursor-pointer"
              ></q-icon> </template
          ></q-select>
        </div>
        <div v-if="cliente.direccionId == ''" class="row">
          <q-select
            class="col-6"
            :dense="true"
            :options="['Bucaramanga', 'Floridablanca', 'Gíron', 'Piedecuesta']"
            bottom-slots
            v-model="cliente.direccion.municipio"
            label="Municipio *"
          >
          </q-select>

          <q-input
            class="col-6 q-px-sm"
            :dense="true"
            bottom-slots
            @keyup.enter="buscarGeo(cliente.direccion)"
            v-model="cliente.direccion.observaciones"
            label="Dirección (Confirma con la tecla ENTER)"
          >
          </q-input>
        </div>

        <!-- informacion de destino -->
        <span class="text-subtitle1"> Información de destino</span>
        <div class="row">
          <div class="col-6">
            <q-select
              :dense="true"
              use-input
              hide-selected
              fill-input
              input-debounce="400"
              @filter="filterZn"
              @input-value="(value) => updateSearchAll(value, 'barrio')"
              :options="zonaOpt"
              :model-value="cliente.zona"
              label="Barrio"
            >
            </q-select>
          </div>
          <div class="col-6 q-px-sm">
            <q-select
              :dense="true"
              use-input
              hide-selected
              fill-input
              @filter="fetchOptionsD"
              :model-value="cliente.destino"
              input-debounce="400"
              :options="listas.direcciones"
              @input-value="(value) => updateSearchAll(value, 'destino')"
              option-label="direccion"
              option-value="id"
              label="Destino"
            >
              <template v-slot:append>
                <q-icon
                  size="xs"
                  v-if="cliente.destino !== ''"
                  name="close"
                  @click="cliente.destino = ''"
                  class="cursor-pointer"
                >
                </q-icon>
              </template>
            </q-select>
          </div>
        </div>

        <!-- informacion general domicilio -->
        <div class="col-3 q-pr-sm">
          <q-select
            :dense="true"
            use-input
            hide-selected
            fill-input
            v-model="cliente.direccion.domiciliario"
            @filter="fetchOptionsDomi"
            @input-value="(value) => updateSearchAll(value, 'domiciliario')"
            :loading="listas.loading"
            prepend-icon="search"
            append-icon="close"
            input-debounce="300"
            :options="listas.backupDomiciliarios"
            option-label="nombres"
            option-value="url"
            label="Asignar domiciliarío (Activos actualmente)"
          >
            <template v-slot:prepend>
              <q-icon
                size="xs"
                name="refresh"
                @click="cargueDomis()"
                class="cursor-pointer"
              >
              </q-icon>
            </template>
            <template v-slot:append>
              <q-icon
                size="xs"
                v-if="cliente.direccion.domiciliario !== ''"
                name="close"
                @click="cliente.direccion.domiciliario = null"
                class="cursor-pointer"
              >
              </q-icon>
            </template>
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Sin resultados
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>

        <div class="row">
          <div class="col-4">
            <q-input
              :dense="true"
              bottom-slots
              v-model="cliente.precio"
              label="Valor del domicilio"
            >
            </q-input>
          </div>
          <div class="col-8 q-px-sm">
            <q-select
              :dense="true"
              use-input
              hide-selected
              fill-input
              input-debounce="400"
              @input-value="(value) => updateSearchAll(value, 'observaciones')"
              :options="['Pedir información a la oficina']"
              :model-value="cliente.direccion.observacionesDomi"
              label="Observaciones del domicilio"
            >
            </q-select>
          </div>
        </div>
        <!-- Enviar -->
        <q-btn
          title="Presiona F2 para enviar"
          :disable="isSending"
          class="col"
          :color="isSending ? 'grey' : 'accent'"
          text-color="white"
          label="enviar"
          @click="cliente.direccionId == '' ? sendDomi() : sendDomicliente()"
        ></q-btn>
      </div>

      <!-- Mapa -->
      <div class="col-5 q-ph-md">
        <template v-if="mapa.mostrarMap">
          <span class="row text-caption"
            >"Reubicar pin" lleva el pin a la oficina, "Volver a buscar" busca
            nuevamente la dirección</span
          >
          <q-btn
            size="x-small"
            class="q-ma-sm"
            color="accent"
            text-color="white"
            label="reubicar pin"
            @click="reubicar()"
          ></q-btn>
          <q-btn
            size="x-small"
            class="q-ma-sm"
            color="accent"
            text-color="white"
            label="Volver a buscar"
            @click="buscarGeo(cliente.direccionId)"
          ></q-btn>
          <l-map
            :zoom="mapa.zoom"
            :center="mapa.center"
            :min-zoom="1"
            :max-zoom="20"
            @click="changeMarker"
          >
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            ></l-tile-layer>
            <l-control-layers position="topright"></l-control-layers>
            <l-marker :lat-lng="mapa.posMapa"></l-marker>
          </l-map>
          <!-- <span class="row"
            >* Debes arrastrar el pin para ajustar la posición en el mapa</span
          > -->
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, reactive, nextTick } from "vue";
import { host, hostDRF, userUtils } from "@/tools/index";
import axios from "axios";
import { useQuasar } from "quasar";
import { latLng } from "leaflet";
import {
  LMap,
  LMarker,
  LTileLayer,
  LControlLayers,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LControlLayers,
  },

  setup() {
    const $q = useQuasar();
    document.addEventListener("keydown", logKey);

    function logKey(e) {
      if (e.key == "F2") {
        if (cliente.direccionId == "") {
          sendDomi();
        } else {
          sendDomicliente();
        }
      }
    }

    const zonaOpciones = reactive([
      "Sur",
      "Cabecera",
      "Cacique",
      "Cañaveral",
      "Centro",
      "Florida",
      "Prado",
      "Provenza",
      "La victoria",
      "Aurora",
      "Terrazas",
      "Altos de cabecera",
      "Alvarez",
      "Real de Minas",
      "San Alonso",
    ]);

    const isSending = ref(false);

    const zonaOpt = ref(zonaOpciones);

    const cliente = reactive({
      confianza: false,
      buscar: "",
      nombre: "",
      nombreId: "",
      whatsapp: "",
      tel: "",
      direccionId: "",
      precio: "",
      destino: "",
      zona: "",
      direccion: {
        municipio: "",
        observaciones: "",
        observacionesDomi: "",
        domiciliario: null,
      },
    });

    const listas = reactive({
      loading: false,
      clientes: [],
      direcciones: [],
      backupDirecciones: [],
      listaDomiciliarios: [],
      backupDomiciliarios: null,
    });

    const mapa = reactive({
      center: [],
      zoom: 16,
      mostrarMap: false,
      posMapa: null,
    });

    const inputDirecciones = ref(null);
    const inputDir = ref(false);
    const inputTelefono = ref(null);
    const inputTel = ref(false);
    const inputMunicipio = ref(null);

    cargueDomis();

    //  buscar geoposición con goole
    function buscarGeo(val) {
      if (val.direccion) {
        var sep = val["direccion"].split(" - ");
        var data = {
          municipio: "",
          observaciones: "",
        };
        data.municipio = sep[0];
        data.observaciones = sep[1];
      } else {
        data = val;
      }
      var API_KEY = "AIzaSyCDhZBktbANJab3gM0s1EZT_o-X_nqf6zs";
      mapa.mostrarMap = false;
      var obs = data.observaciones.replace("#", " ");
      axios
        .post(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${data.municipio},${obs}&key=${API_KEY}`
        )
        .then(async (rta) => {
          mapa.posMapa = null;
          mapa.center = [];
          mapa.posMapa = {
            lat: parseFloat(rta.data.results[0]["geometry"]["location"]["lat"]),
            lng: parseFloat(rta.data.results[0]["geometry"]["location"]["lng"]),
          };
          mapa.center.push(
            parseFloat(rta.data.results[0]["geometry"]["location"]["lat"])
          );
          mapa.center.push(
            parseFloat(rta.data.results[0]["geometry"]["location"]["lng"])
          );
          mapa.mostrarMap = true;
          if (val.direccion) {
            savePoint(mapa.posMapa);
          }
        });
    }

    // Cambio de dirección de recogida, si ya tienen lat y lon guardada lo ubica, de lo contario busca con la función buscarGeo()
    function direccionGeo(val) {
      console.log(val);
      mapa.mostrarMap = false;
      mapa.center = [];
      if (val) {
        cliente.direccionId = val;
        if (!val["lat"] && !val["lng"]) {
          buscarGeo(val);
        } else {
          mapa.posMapa = {
            lat: parseFloat(val["lat"]),
            lng: parseFloat(val["lng"]),
          };
          mapa.center.push(val["lat"]);
          mapa.center.push(val["lng"]);
          mapa.mostrarMap = true;
        }
      } else {
        reubicar();
      }
    }

    function changeMarker(a) {
      if (a) {
        mapa.posMapa = a.latlng;
        if (cliente.direccionId.id) {
          savePoint(mapa.posMapa);
        }
      }
    }

    function savePoint() {
      if (mapa.posMapa?.lat) {
        userUtils.getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("idDireccion", cliente.direccionId.id);
          fd.append("lat", mapa.posMapa.lat);
          fd.append("lon", mapa.posMapa.lng);
          axios
            .post(host + "editaGeo", fd)
            .then((result) => {
              if (result.data.rta != "OK")
                $q.notify({
                  type: "warning",
                  message:
                    "No se guardo esta dirección correctamente vuelva a ubicar el punto en el mapa",
                  timeout: 1500,
                });
            })
            .catch((error) => {
              var msj = error.response.data.rta;
              $q.notify({ type: "negative", message: msj, timeout: 1500 });
            });
        });
      }
    }

    const fetchOptions = async (val, update) => {
      if (val === "") {
        update();
        return;
      } else if (val != "") {
        var by = isNaN(val) ? "nombres" : "numero";
        cliente.buscar = val;
        listas.clientes = [];
        listas.loading = true;
        if (val.toString().length > 2) {
          userUtils.getToken().then((token) => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append(by, val);
            axios.post(host + "buscaCliente", fd).then(async (result) => {
              listas.loading = false;
              if (result.data.clientes.length != 0) {
                var cli = result.data.clientes;
                listas.clientes = cli;
              } else {
                // asigno para crear un nuevo cliente
                listas.clientes = [
                  { nombres: `Crear nuevo cliente ${val}`, id: "Crear nuevo" },
                ];
              }
            });
          });
          update();
          return;
        }
      }
    };

    function filterZn(val, update) {
      if (val === "") {
        update(() => {
          zonaOpt.value = zonaOpciones;
        });
        return;
      } else if (val != "") {
        const needle = val.toLowerCase();
        var d = zonaOpciones.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
        if (d.length != 0) {
          update(() => {
            zonaOpt.value = d;
          });
        } else {
          update(() => {
            zonaOpt.value = val;
          });
        }
      }
    }

    function fetchOptionsD(val, update) {
      if (val === "") {
        update();
        return;
      } else if (val != "") {
        cliente.destino = val;
        update();
        return;
      }
    }

    function fetchOptionsDomi(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        listas.backupDomiciliarios = listas.listaDomiciliarios.filter(
          (v) => v.nombres.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    }

    function changeToE() {
      var by = isNaN(cliente.buscar) ? "nombres" : "numero";
      if (by == "nombres") {
        cliente.nombre = cliente.buscar;
        cliente.tel = "";
      } else {
        cliente.nombre = "";
        cliente.tel = cliente.buscar;
      }
      clear("");
      inputTel.value = true;
      nextTick(() => {
        inputTelefono.value.focus();
      });
    }

    // update por casos
    function updateSearchAll(a, tipo) {
      switch (tipo) {
        case "cliente":
          if (a.id == "Crear nuevo") {
            clear("clienteNuevo");
            inputTel.value = true;
            nextTick(() => {
              inputTelefono.value.focus();
            });
          } else {
            cliente.buscar = a.nombres;
            cliente.nombre = a.nombres;
            cliente.tel = a.telefono;
            cliente.whatsapp = a.whatsapp;
            asignarInfo(a);
          }
          break;
        case "destino":
          cliente.destino = a;
          break;
        case "barrio":
          cliente.zona = a;
          break;
        case "observaciones":
          cliente.direccion.observacionesDomi = a;
          break;
        case "domiciliario":
          if (a != "") {
            cliente.direccion.domiciliario = a;
          } else {
            cliente.direccion.domiciliario = null;
          }
          break;
        default:
          break;
      }
    }

    function asignarInfo(info) {
      listas.direcciones = [];
      listas.backupDirecciones = [];
      var data = listas.clientes.find(
        (e) => e.nombres == info.nombres || e.telefono == info.telefono
      );
      cliente.nombreId = data.id;
      cliente.whatsapp = data.whatsapp;
      inputTel.value = true;
      var direc = data.direcciones;
      for (let d = 0; d < direc.length; d++) {
        const element = direc[d];
        if (element["cantidad"] > 0) {
          var indi =
            element["indicaciones"] == "null"
              ? ""
              : " - " + element["indicaciones"];
          listas.direcciones.push({
            id: element["id"],
            direccion: element["ciudad"] + indi,
            lat: element["lat"],
            lng: element["lon"],
          });
          listas.backupDirecciones = listas.direcciones;
        }
      }
      cliente.direccionId = listas.direcciones[0];
      var dirp = listas.direcciones[0];
      if (dirp != undefined) {
        if (dirp["lng"] != undefined && dirp["lat"] != undefined) {
          mapa.posMapa = {
            lat: dirp["lat"],
            lng: dirp["lng"],
          };
          mapa.center.push(dirp["lat"]);
          mapa.center.push(dirp["lng"]);
          mapa.mostrarMap = true;
        } else {
          reubicar();
        }
      }
      inputDir.value = true;
      nextTick(() => {
        inputDirecciones.value.focus();
      });
    }

    function cargueDomis() {
      listas.listaDomiciliarios = [];
      userUtils.getToken().then((token) => {
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .get(hostDRF + "domiciliarios/?activo=1", {
            headers: headers,
            params: {
              page_size: 1000,
            },
          })
          .then((result) => {
            listas.listaDomiciliarios = listas.backupDomiciliarios =
              result.data.results;
          });
      });
    }

    // function cargueDomis() {
    //   listas.listaDomiciliarios = [];
    //   userUtils.getToken().then((token) => {
    //     const fd = new FormData();
    //     fd.append("token", token);
    //     axios
    //       .post(host + "listaDomiciliarios", fd)
    //       .then((result) => {
    //         if (result.data.rta.length != 0) {
    //           var data = result.data.rta;
    //           for (let d = 0; d < data.length; d++) {
    //             const element = data[d];
    //             if (element["activo"] == true) {
    //               listas.listaDomiciliarios.push({
    //                 nombres: element["nombres"] + " " + element["apellidos"],
    //                 id: element["id"],
    //               });
    //             }
    //           }
    //           listas.backupDomiciliarios = listas.listaDomiciliarios;
    //         }
    //       })
    //       .catch((error) => {
    //         var msj = error.response.data.rta;
    //         $q.notify({ type: "negative", message: msj, timeout: 1500 });
    //       });
    //   });
    // }

    function sendDomi() {
      if (isSending.value == false) {
        isSending.value = true;
        if (cliente.direccion.domiciliario) {
          var splitURL = cliente.direccion.domiciliario.url.split("/");
          var id = splitURL.slice(-2)[0];
        }
        if (
          cliente.nombre &&
          cliente.tel &&
          cliente.direccion.municipio &&
          cliente.direccion.observaciones
        ) {
          if (mapa.mostrarMap) {
            userUtils.getToken().then((token) => {
              const fd = new FormData();
              fd.append("token", token);
              fd.append("nombres", cliente.nombre);
              fd.append("telefono", cliente.tel);
              fd.append("whatsapp", cliente.whatsapp);
              fd.append(
                "domiciliario",
                cliente.direccion.domiciliario != null
                  ? id
                  : cliente.direccion.domiciliario
              );
              fd.append("lat", mapa.posMapa.lat);
              fd.append("lon", mapa.posMapa.lng);
              fd.append("ciudad", cliente.direccion.municipio);
              fd.append("indicaciones", cliente.direccion.observaciones);
              fd.append(
                "observaciones_admin",
                cliente.direccion.observacionesDomi
              );
              fd.append("precio", cliente.precio == "" ? 0 : cliente.precio);
              fd.append("destino", cliente.destino);
              fd.append("zona", cliente.zona);
              fd.append("confianza", cliente.confianza == true ? "SI" : "NO");
              axios
                .post(host + "agregaCliente", fd)
                .then((result) => {
                  isSending.value = false;
                  if (result.data.rta == "OK") {
                    clear("");
                    $q.notify({
                      type: "positive",
                      message: "Domicilio enviado",
                      timeout: 1500,
                    });
                  } else {
                    $q.notify({
                      type: "negative",
                      message: "Error: no se puedo generar este domicilio",
                      timeout: 1500,
                    });
                  }
                })
                .catch((error) => {
                  isSending.value = false;
                  $q.notify({
                    type: "negative",
                    message: error.response.data.rta,
                    timeout: 1500,
                  });
                });
            });
          } else {
            isSending.value = false;
            $q.notify({
              type: "warning",
              message: "Error: Confirma la ubicación en el mapa",
              timeout: 2000,
            });
          }
        } else {
          isSending.value = false;
          $q.notify({
            type: "warning",
            message: "Error: Faltan datos",
            timeout: 2000,
          });
        }
      }
    }

    function sendDomicliente() {
      if (isSending.value == false) {
        if (cliente.direccion.domiciliario) {
          var splitURL = cliente.direccion.domiciliario.url.split("/");
          var id = splitURL.slice(-2)[0];
        }
        isSending.value = true;
        if (cliente.direccionId != "") {
          userUtils.getToken().then((token) => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append("clienteId", cliente.nombreId);
            fd.append("direccionId", cliente.direccionId["id"]);
            fd.append(
              "domiciliario",
              cliente.direccion.domiciliario != null
                ? id
                : cliente.direccion.domiciliario
            );
            fd.append("destino", cliente.destino);
            fd.append("zona", cliente.zona);
            fd.append("confianza", cliente.confianza == true ? "SI" : "NO");
            fd.append(
              "observaciones_admin",
              cliente.direccion.observacionesDomi
            );
            fd.append("precio", cliente.precio == "" ? 0 : cliente.precio);
            axios
              .post(host + "domiDireccion", fd)
              .then((result) => {
                isSending.value = false;
                if (result.data.rta == "OK") {
                  clear("");
                  $q.notify({
                    type: "positive",
                    message: "Domicilio enviado",
                    timeout: 1500,
                  });
                } else {
                  $q.notify({
                    type: "negative",
                    message: "Error: no se puedo generar este domicilio",
                    timeout: 1500,
                  });
                }
              })
              .catch((error) => {
                isSending.value = false;
                $q.notify({
                  type: "negative",
                  message: error.response.data.rta,
                  timeout: 1500,
                });
              });
          });
        } else {
          $q.notify({
            type: "warning",
            message: "Error: Faltan datos",
            timeout: 2000,
          });
        }
      }
    }

    function changeDirecciones() {
      if (cliente.direccionId == null || cliente.direccionId == "") {
        cliente.direccionId = cliente.destino;
        cliente.destino = "";
      } else {
        mapa.center = [];
        mapa.mostrarMap = false;
        mapa.posMapa = null;
        cliente.destino = cliente.direccionId;
        cliente.direccionId = "";
      }
    }

    function noRegistradas() {
      cliente.direccionId = "";
      mapa.mostrarMap = false;
      mapa.posMapa = null;
      mapa.center = [];
    }

    // Si no envio nada borra todo, de lo contrario
    // estoy creando un nuevo cliente
    function clear(a) {
      if (a === "clienteNuevo") {
        cliente.nombre = cliente.buscar;
      } else {
        cliente.nombre = "";
      }
      cliente.tel = "";
      cliente.direccion.municipio = "";
      listas.clientes = [];
      listas.direcciones = [];
      listas.backupDirecciones = [];
      cliente.nombreId = "";
      cliente.whatsapp = "";
      cliente.direccionId = "";
      cliente.direccion.observaciones = "";
      cliente.direccion.observacionesDomi = "";
      cliente.direccion.domiciliario = null;
      cliente.precio = "";
      cliente.destino = "";
      cliente.zona = "";
      cliente.confianza = false;
      mapa.posMapa = null;
      mapa.center = [];
      mapa.mostrarMap = false;
      inputTel.value = false;
    }

    function reubicar() {
      mapa.mostrarMap = false;
      mapa.center = latLng(7.121113247309666, -73.11342428633715);
      mapa.posMapa = latLng(7.121113247309666, -73.11342428633715);
      mapa.zoom = 16;
      mapa.mostrarMap = true;
    }

    const fetchDirecRegister = async (val, update) => {
      if (val === "") {
        update(() => {
          listas.direcciones = listas.backupDirecciones;
        });
        return;
      } else {
        var find = listas.backupDirecciones.filter((e) => {
          if (e.direccion.toString().includes(val)) {
            return e;
          } else {
            listas.direcciones = listas.backupDirecciones;
          }
        });
        update(() => {
          listas.direcciones = find;
        });
      }
    };

    return {
      listas,
      cliente,
      mapa,
      isSending,
      inputDirecciones,
      inputDir,
      inputTelefono,
      inputTel,
      inputMunicipio,
      zonaOpt,
      clear,
      asignarInfo,
      cargueDomis,
      sendDomi,
      sendDomicliente,
      fetchOptions,
      fetchOptionsD,
      fetchOptionsDomi,
      changeToE,
      changeDirecciones,
      updateSearchAll,
      filterZn,
      direccionGeo,
      buscarGeo,
      changeMarker,
      noRegistradas,
      reubicar,
      fetchDirecRegister,
    };
  },
};
</script>
<style lang="scss" scoped>
.foobar1 {
  /* <--- class we added above */
  width: 50%;
  height: 200px;
  margin-bottom: 5% !important;
}
</style>
