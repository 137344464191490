<template >
  <div class="fixed-center" v-if="listaDomiciliarios.isSearch">
    <q-circular-progress
      indeterminate
      size="50px"
      color="orange"
      class="q-ma-md"
    ></q-circular-progress>
  </div>
  <q-table
    dense
    flat
    bordered
    separator="cell"
    rows-per-page-label="Items por pgna"
    v-else
    title="Domiciliarios"
    :rows="listaDomiciliarios.data"
    :columns="columns"
    row-key="id"
    :visible-columns="visibleColumns"
    no-data-label="Sin información"
    hide-pagination
    :rows-per-page-options="[listaDomiciliarios.itemPerPage]"
  >
    <template v-slot:top-right>
      <q-input
        dense
        fill-input
        v-model="listaDomiciliarios.search"
        @update:model-value="fetchOptionsDomi"
        prepend-icon="search"
        append-icon="close"
        label="Domiciliario"
      >
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey"> Sin resultados </q-item-section>
          </q-item>
        </template>
      </q-input>
    </template>

    <template v-slot:body="props">
      <q-tr :props="props">
        <!-- <q-td key="disponible"  :props="props">
          <q-icon
            size="10px"
            :color="props.row.info.disponible == true ? 'green' : 'red'"
            name="circle"
          >
          </q-icon>
        </q-td> -->
        <q-td key="confianza" :props="props">
          <q-chip
            size="xs"
            clickable
            :color="props.row.info.confianza ? 'orange' : 'grey'"
            text-color="white"
            @click="
              changeData(props.row.info, 'confianza', !props.row.info.confianza)
            "
            dense
            class="text-weight-bolder"
            square
            >{{ props.row.info.confianza ? "SI" : "NO" }}</q-chip
          >
        </q-td>
        <q-td key="bloqueado" :props="props">
          <q-chip
            size="xs"
            clickable
            :color="props.row.info.bloqueado ? 'red' : 'grey'"
            text-color="white"
            @click="
              changeData(props.row.info, 'bloqueado', !props.row.info.bloqueado)
            "
            dense
            class="text-weight-bolder"
            square
            >{{ props.row.info.bloqueado ? "SI" : "NO" }}</q-chip
          >
        </q-td>

        <q-td key="maxDomicilios" :props="props">
          <q-input
            class="personal_input"
            @change="
              changeData(props.row.info, 'max_srvs', props.row.info.max_srvs)
            "
            dense
            borderless
            v-model="props.row.info.max_srvs"
            type="number"
          ></q-input>
        </q-td>
        <q-td key="tiempoFin" :props="props">
          <q-input
            class="personal_input"
            @change="
              changeData(
                props.row.info,
                'tiempoFinalizacion',
                props.row.info.tiempoFinalizacion
              )
            "
            dense
            borderless
            v-model="props.row.info.tiempoFinalizacion"
            type="number"
          ></q-input>
        </q-td>

        <q-td key="llamar" :props="props">
          <q-btn size="xs" flat @click="llamarDomi(props.row.info)"
            >Llamar</q-btn
          >
        </q-td>
        <q-td key="nombres" :props="props">
          {{ props.row.nombres }}
        </q-td>
        <q-td key="fechaIngreso" :props="props">
          {{ $filters.timeAprox(props.row.info.fechaIngreso) }}
        </q-td>
        <!-- <q-td key="horaAlmuerzo" :props="props">
          {{ $filters.timeComplete(props.row.horaAlmuerzo) }}
        </q-td> -->
        <q-td key="documento" :props="props">
          {{ props.row.info.documento }}
        </q-td>
        <q-td key="celular" :props="props">
          {{ props.row.info.celular }}
        </q-td>
        <q-td key="activo" :props="props">
          <q-chip
            size="xs"
            clickable
            :color="props.row.info.activo ? 'green' : 'grey'"
            text-color="white"
            @click="
              changeData(props.row.info, 'activo', !props.row.info.activo)
            "
            dense
            class="text-weight-bolder"
            square
            >{{ props.row.info.activo ? "Activo" : "Inactivo" }}</q-chip
          >
        </q-td>
        <q-td key="ver" :props="props">
          <q-btn size="xs" dense color="accent" @click="verDomi(props.row.info)"
            >Ver</q-btn
          >
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <div class="row justify-end" v-if="!listaDomiciliarios.isSearch">
    <span class="q-mt-sm q-mr-md text-primary">
      Total items: {{ pagination.count }}
    </span>
  </div>

  <div class="row justify-end" v-if="!listaDomiciliarios.isSearch">
    <span class="q-mt-sm text-primary">
      Items por pgna: {{ listaDomiciliarios.itemPerPage }}
    </span>
    <q-btn
      icon="chevron_left"
      color="grey-8"
      round
      dense
      flat
      :disable="pagination.previous === null ? true : false"
      @click="cargueDomis(pagination.previous)"
    ></q-btn>
    <q-btn
      icon="chevron_right"
      color="grey-8"
      round
      dense
      flat
      :disable="pagination.next === null ? true : false"
      @click="cargueDomis(pagination.next)"
    ></q-btn>
  </div>
  <q-dialog
    transition-show="slide-up"
    transition-hide="slide-down"
    v-model="domiciliario.openPopupDomi"
    v-if="domiciliario.openPopupDomi"
  >
    <domiciliarioC
      :info="domiciliario.data"
      @close-popup-domiciliario="closeP($event)"
    ></domiciliarioC>
  </q-dialog>
</template>


<script>
import { host, userUtils, configUtils, hostDRF } from "@/tools/index";
import axios from "axios";
import { useQuasar } from "quasar";
import { onMounted, reactive, ref } from "vue";
import domiciliarioC from "@/components/DeliveryMan/TemplatePopup.vue";

export default {
  components: { domiciliarioC },
  setup() {
    const $q = useQuasar();

    onMounted(async () => {
      try {
        listaDomiciliarios.maxDomiciliosTodos =
          await configUtils.getServiciosConcurrentes();
      } catch (error) {
        $q.notify({ type: "negative", message: error });
      }
    });

    const listaDomiciliarios = reactive({
      isSearch: true,
      search: "",
      data: [],
      backup: [],
      maxDomiciliosTodos: 0,
      itemPerPage: 10,
    });

    const domiciliario = reactive({
      data: [],
      openPopupDomi: false,
    });

    const pagination = reactive({
      next: "",
      previous: "",
      count: 0,
    });

    cargueDomis(hostDRF + "domiciliarios/");

    function cargueDomis(url) {
      listaDomiciliarios.isSearch = true;
      listaDomiciliarios.data = [];
      userUtils.getToken().then((token) => {
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .get(url, {
            headers: headers,
            params: {
              page_size: listaDomiciliarios.itemPerPage,
            },
          })
          .then((result) => {
            var data = result.data.results;
            pagination.next = result.data.next;
            pagination.previous = result.data.previous;
            pagination.count = result.data.count;
            data.forEach((element) => {
              listaDomiciliarios.isSearch = false;
              listaDomiciliarios.data.push({
                nombres:
                  element["nombres"] != ""
                    ? element["nombres"] + " " + element["apellidos"]
                    : "",
                id: element["id"],
                info: element,
              });
            });
            console.log(listaDomiciliarios.data.length);
            console.log(result.data.results.length);
            listaDomiciliarios.backup = listaDomiciliarios.data;
          });
      });
    }

    const columns = ref([
      {
        label: "Confianza",
        name: "confianza",
        field: "confianza",
        sortable: true,
        style: "width: 8px",
      },
      {
        label: "Bloqueado",
        name: "bloqueado",
        field: "bloqueado",
        sortable: true,
        style: "width: 8px",
      },
      {
        label: "Mx.domis",
        name: "maxDomicilios",
        field: "maxDomicilios",
        sortable: true,
        style: "width: 10px !important",
      },
      {
        label: "T.fin(min)",
        name: "tiempoFin",
        field: "tiempoFinalizacion",
        sortable: true,
        style: "width: 10px !important",
      },
      {
        label: "Llamar",
        name: "llamar",
        field: "llamar",
        sortable: true,
      },

      { label: "Nombres", name: "nombres", field: "nombres", sortable: true },
      {
        label: "F.Ingreso",
        name: "fechaIngreso",
        field: "fechaIngreso",
        sortable: true,
      },
      {
        label: "Documento",
        name: "documento",
        field: "documento",
        sortable: true,
      },
      {
        label: "Teléfono",
        name: "celular",
        field: "celular",
        sortable: true,
      },
      { label: "Activo", name: "activo", field: "activo", sortable: true },
      {
        label: "Ver",
        name: "ver",
        field: "ver",
        sortable: true,
      },
    ]);

    function changeData(a, name, dato) {
      userUtils.getToken().then((token) => {
        let fd = null;
        if (name === "activo") {
          fd = {
            disponible: dato,
            [name]: dato,
          };
        } else {
          fd = {
            [name]: dato,
          };
        }
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .patch(a.url, fd, { headers: headers })
          .then((result) => {
            let data = result.status;
            if (data === 200) {
              $q.notify({
                type: "positive",
                message: `Se ajustó el ${name} de ${a.nombres} a ${dato}`,
                timeout: 2000,
              });
              cargueDomis(hostDRF + "domiciliarios/");
            }
          })
          .catch((error) => {
            var msj = error.response.data.detail;
            $q.notify({ type: "negative", message: msj, timeout: 1500 });
          });
      });
    }

    function editMaxDomi(a) {
      userUtils.getToken().then((token) => {
        const fd = {
          activo: a.max_srvs,
        };
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .patch(a.url, fd, { headers: headers })
          .then((result) => {
            let data = result.status;
            if (data === 200) {
              $q.notify({
                type: "positive",
                message:
                  "Ajuste de cantidad maxima de domicilios del domiciliario " +
                  a.nombres,
                timeout: 2000,
              });
              cargueDomis(hostDRF + "domiciliarios/");
            }
          })
          .catch((error) => {
            var msj = error.response.data.detail;
            $q.notify({ type: "negative", message: msj, timeout: 1500 });
          });
      });
    }

    async function changeMaxDomi() {
      cargueDomis(hostDRF + "domiciliarios/");
      await configUtils.setServiciosConcurrentes(
        listaDomiciliarios.maxDomiciliosTodos
      );
    }

    function verDomi(info) {
      domiciliario.data = info;
      domiciliario.openPopupDomi = true;
    }

    function closeP(e) {
      if (e) cargueDomis(hostDRF + "domiciliarios/");
      domiciliario.openPopupDomi = false;
    }

    function llamarDomi(a) {
      const url = a.url.split("/");
      const id = url.slice(-2)[0];
      userUtils
        .getToken()
        .then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("domiciliarioId", id);
          axios.post(host + "alertaCom", fd).then((result) => {
            let data = result.data.rta;
            if (data == "OK") {
              $q.notify({
                type: "positive",
                message: "Notificación enviada a: " + a.nombres,
              });
            }
          });
        })
        .catch((error) => {
          var msj = error.response.data.rta;
          $q.notify({ type: "negative", message: msj, timeout: 1500 });
        });
    }

    function myfiltermethod(rows, terms, cols) {
      const lowerSearch = terms ? terms.toLowerCase() : "";
      const filt = rows.filter((row) => {
        let s1 = true;
        let ans = false;
        if (lowerSearch != "") {
          s1 = false;
          let values = Object.values(row);
          let keys = Object.keys(row);
          let lower = values.map((x, i) => {
            var element = [];
            for (let s = 0; s < cols.length; s++) {
              element.push(cols[s]["field"]);
            }

            if (x != null && element.includes(keys[i])) {
              return x.toString().toLowerCase();
            }
          });
          for (let val = 0; val < lower.length; val++) {
            if (lower[val] != null) {
              if (lower[val].includes(lowerSearch)) {
                s1 = true;
                break;
              }
            }
          }
        }
        ans = false;
        if (s1) {
          ans = true;
        }
        return ans;
      });
      return filt;
    }

    function domiConfianza(a) {
      console.log(a.confianza);
      userUtils.getToken().then((token) => {
        const fd = {
          confianza: a.confianza == true ? "NO" : "SI",
        };
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .patch(a.url, fd, { headers: headers })
          .then((result) => {
            let data = result.status;
            if (data == 200) {
              $q.notify({
                type: "positive",
                message: `Se ajusto el domiciliario ${a.nombres} a confianza ${
                  a.confianza == true ? '"NO"' : '"SI"'
                }`,
              });
              cargueDomis(hostDRF + "domiciliarios/");
            } else {
              $q.notify({ type: "warning", message: "Error!" });
            }
          })
          .catch((error) => {
            var msj = error.response.data.detail;
            $q.notify({ type: "negative", message: msj, timeout: 1500 });
          });
      });
    }

    function blockDomi(a) {
      userUtils.getToken().then((token) => {
        const fd = {
          bloqueado: !a.bloqueado,
        };
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .patch(a.url, fd, { headers: headers })
          .then((result) => {
            let data = result.status;
            if (data == 200) {
              $q.notify({
                type: "positive",
                message: `Se ajusto el domiciliario ${a.nombres} a bloqueado ${
                  a.bloqueado == true ? '"NO"' : '"SI"'
                }`,
              });
              cargueDomis(hostDRF + "domiciliarios/");
            } else {
              $q.notify({ type: "warning", message: "Error!" });
            }
          })
          .catch((error) => {
            var msj = error.response.data.detail;
            $q.notify({ type: "negative", message: msj, timeout: 2000 });
          });
      });
    }

    function editTiempoDomis(a) {
      userUtils.getToken().then((token) => {
        const fd = {
          tiempo: a.tiempoFinalizacion,
        };
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .patch(a.url, fd, { headers: headers })
          .then((result) => {
            let data = result.result;
            if (data == 200) {
              $q.notify({
                type: "positive",
                message: `Se ajusto el tiempo de finalización del domiciliario ${a.nombres}`,
              });
            } else {
              $q.notify({
                type: "warning",
                message:
                  "No se ajusto correctamente el tiempo del finalización del domiciliario",
              });
            }
          })
          .catch((error) => {
            var msj = error.response.data.detail;
            $q.notify({ type: "negative", message: msj, timeout: 2000 });
          });
      });
    }

    function fetchOptionsDomi(val) {
      listaDomiciliarios.search = val;
      if (val != null) {
        userUtils.getToken().then((token) => {
          const headers = {
            "Content-Type": "application/json chatset=UFT-8",
            Authorization: token,
          };
          axios
            .get(hostDRF + `domiciliarios/?busqueda=${val}`, {
              headers: headers,
              params: {
                page_size: listaDomiciliarios.itemPerPage,
              },
            })
            .then((rta) => {
              listaDomiciliarios.data = [];
              var data = rta.data.results;
              pagination.next = rta.data.next;
              pagination.previous = rta.data.previous;
              pagination.count = rta.data.count;
              data.forEach((element) => {
                listaDomiciliarios.isSearch = false;
                listaDomiciliarios.data.push({
                  nombres: element["nombres"] + " " + element["apellidos"],
                  id: element["id"],
                  info: element,
                });
              });
            });
        });
      } else {
        listaDomiciliarios.data = [];
      }
    }

    function updateSearchDomi(a) {
      console.log(a);
      if (a != "") {
        listaDomiciliarios.search = a;
      } else {
        listaDomiciliarios.search = null;
      }
    }

    return {
      fetchOptionsDomi,
      updateSearchDomi,
      domiConfianza,
      blockDomi,
      myfiltermethod,
      listaDomiciliarios,
      columns,
      domiciliario,
      changeMaxDomi,
      cargueDomis,
      editTiempoDomis,
      verDomi,
      closeP,
      editMaxDomi,
      llamarDomi,
      pagination,
      visibleColumns: ref([
        "nombres",
        "fechaIngreso",
        "documento",
        "celular",
        "activo",
        "delete",
        "maxDomicilios",
        "confianza",
        "bloqueado",
        "tiempoFin",
        "ver",
        "llamar",
      ]),
      // domiActivate,
      changeData,
    };
  },
};
</script>

<style scoped>
th,
td {
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
}

.personal_input {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
</style>