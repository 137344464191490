<template>
  <div>
    <h4 class="text-subtitle1 q-ma-none text-primary">
      Historial disponibilidad / activación:
    </h4>
    <div class="row">
      <div class="col-5 q-ma-md">
        <q-input filled v-model="dateI" mask="date" :rules="['dateI']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  :locale="myLocale"
                  color="primary"
                  minimal
                  v-model="dateI"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="primary"
                      flat
                    ></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-5 q-ma-md">
        <q-input filled v-model="dateF" mask="date" :rules="['dateF']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  :locale="myLocale"
                  minimal
                  color="primary"
                  v-model="dateF"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="primary"
                      flat
                    ></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-12">
        <span class="text-subtitle1 text-primary">
          Ajustes de disponibilidad</span
        >

        <div v-for="hist in historial.data" :key="hist">
          <span
            >{{ $filters.timeComplete(hist.inicio) }} -
            {{ $filters.timeComplete(hist.fin) }} :
            {{
              hist.motivoFin == 1
                ? "Fin jornada"
                : hist.motivoFin == 4
                ? "Bloqueo"
                : hist.motivoFin == 2
                ? "Almuerzo"
                : "Otro"
            }}
          </span>
        </div>
        <span class="text-subtitle1 column" v-if="historial.data.length == 0"
          >* No se tiene registro</span
        >
      </div>
      <div class="col-12">
        <span class="text-subtitle1 text-primary"> Horas de activación</span>
        <div v-for="hist in historial.activacion" :key="hist">
          <span class="text-subtitle1">
            Hora de activación: {{ $filters.timeComplete(hist.fechaHora) }}
          </span>
        </div>
        <span
          class="text-subtitle1 column"
          v-if="historial.activacion.length == 0"
          >* No se tiene registro</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import moment from "moment";
import { userUtils, host } from "@/tools/index";
import axios from "axios";
import { useQuasar } from "quasar";

export default {
  name: "DomiciliarioHisto",
  props: {
    id: String,
  },
  setup(props) {
    const $q = useQuasar();
    const dateI = ref("");
    const dateF = ref("");

    const historial = reactive({
      data: [],
      activacion: [],
      isSearch: false,
      isNull: true,
    });

    onMounted(() => {
      var dat = new Date();
      dateI.value = moment(dat).add(-1, "days").format("YYYY/MM/DD");
      dateF.value = moment(dat).format("YYYY/MM/DD");
    });

    watch([dateI, dateF], ([newA, newB]) => {
      if (newA && newB) {
        cargueHist();
      }
    });

    function cargueHist() {
      console.log(props.id);
      if (props.id) {
        var splitURL = props.id.split("/");
        var id = splitURL.slice(-2)[0];
      }
      var i = moment(dateI.value).format("YYYY-MM-DD");
      var f = moment(dateF.value).format("YYYY-MM-DD");
      userUtils.getToken().then((token) => {
        historial.isSearch = true;
        const fd = new FormData();
        fd.append("token", token);
        fd.append("fInicio", i);
        fd.append("fFin", f);
        fd.append("domiciliarioId", id);
        axios
          .post(host + "historialActivacionDomiciliario", fd)
          .then((result) => {
            historial.isSearch = false;
            historial.isNull = false;
            if (result.data["lista"].length != 0) {
              historial.data = result.data["lista"];
            }
            if (result.data["listaActivacion"].length != 0) {
              historial.activacion = result.data["listaActivacion"];
            } else {
              historial.isNull = true;
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    return {
      cargueHist,
      dateI,
      dateF,
      historial,
      myLocale: {
        /* starting with Sunday */
        days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
        daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
        months:
          "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
            "_"
          ),
        monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split(
          "_"
        ),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: "dias",
      },
    };
  },
};
</script>

<style>
</style>