import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import { initializeApp } from 'firebase/app'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import { getAuth } from 'firebase/auth'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import { createPinia } from 'pinia'
import 'leaflet/dist/leaflet.css';

const firebaseConfig = {
  apiKey: "AIzaSyDUVe1PrYAmTid0ZkH0oJlLWb1bIz10G8s",
  authDomain: "alianza-mensajeros.firebaseapp.com",
  databaseURL: "https://alianza-mensajeros.firebaseio.com",
  projectId: "alianza-mensajeros",
  storageBucket: "alianza-mensajeros.appspot.com",
  messagingSenderId: "660374940978",
  appId: "1:660374940978:web:2c9ef0fa40e42b3182fb2b"
};

initializeApp(firebaseConfig);
const auth = getAuth()
const pinia = createPinia()

const app = createApp(App)

app.config.globalProperties.$filters = {
  timeComplete(date) {
    return moment(date).format('YYYY/MM/DD - hh:mm:ss A') 
  },

  timeAprox(date) {
    return moment(date).format('YYYY/MM/DD - hh:mm A') 
  },

  money(string) {
    var formatter = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits:0,
    });
    return formatter.format(string)
  }
}


app.config.keyCodes = {
  f1: 112
}

app.use(Quasar, quasarUserOptions).use(pinia).use(VueAxios, axios).use(VueVideoPlayer).use(router).use(useQuasar, {
    plugins: {
        Notify,
  },
})


app.mount('#app')


export { auth }
