<template>
  <div class="row">
    <div class="col-3 q-ma-md">
      <q-input :dense="true" v-model="dateI" mask="date" :rules="['dateI']">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :locale="myLocale"
                color="primary"
                minimal
                v-model="dateI"
              >
                <div class="row items-center justify-end">
                  <q-btn
                    v-close-popup
                    label="Cerrar"
                    color="primary"
                    flat
                  ></q-btn>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <div class="col-3 q-ma-md">
      <q-input :dense="true" v-model="dateF" mask="date" :rules="['dateF']">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :locale="myLocale"
                minimal
                color="primary"
                v-model="dateF"
              >
                <div class="row items-center justify-end">
                  <q-btn
                    v-close-popup
                    label="Cerrar"
                    color="primary"
                    flat
                  ></q-btn>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <!-- <div class="col-3 q-ma-md">
      <q-btn @click="cargueDomis()" color="accent"> Buscar </q-btn>
    </div> -->
  </div>
  <div v-if="listaDomicilios.data.length != 0" class="full-width">
    <domicilios-lista :dataLista="listaDomicilios.data" historial="true">
    </domicilios-lista>
  </div>
  <div
    class="column q-mx-md"
    v-if="!listaDomicilios.isSearch && listaDomicilios.data.length == 0"
  >
    <span class="text-subtitle1">* No hay domicilios</span>
  </div>
  <div class="fixed-center" v-if="listaDomicilios.isSearch">
    <q-circular-progress
      indeterminate
      size="50px"
      color="orange"
      class="q-ma-md"
    ></q-circular-progress>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import axios from "axios";
import { host, userUtils } from "@/tools/index";
import { useQuasar } from "quasar";
import moment from "moment";
import domiciliosLista from "@/components/Delivery/ManagementDelivery.vue";

export default {
  components: {
    domiciliosLista,
  },
  setup() {
    const $q = useQuasar();
    const dateI = ref("");
    const dateF = ref("");

    const listaDomicilios = reactive({
      data: [],
      isSearch: false,
      isNull: false,
    });

    watch([dateI, dateF], ([newA, newB]) => {
      if (newA && newB) {
        cargueDomis();
      }
    });

    onMounted(() => {
      var dat = new Date();
      dateI.value = moment(dat).add(-1, "days").format("YYYY/MM/DD");
      dateF.value = moment(dat).format("YYYY/MM/DD");
      cargueDomis();
    });

    function cargueDomis() {
      listaDomicilios.data = [];
      var i = moment(dateI.value).format("YYYY-MM-DD");
      var f = moment(dateF.value).format("YYYY-MM-DD");
      userUtils.getToken().then((token) => {
        listaDomicilios.isSearch = true;
        const fd = new FormData();
        fd.append("token", token);
        fd.append("fechaInicio", i);
        fd.append("fechaFin", f);
        axios
          .post(host + "listaDomicilios", fd)
          .then((result) => {
            listaDomicilios.isSearch = false;
            if (result.data["historial"].length != 0) {
              var info = result.data["historial"];
              info.forEach((element) => {
                var name =
                  element["domiciliario_nombres"] == null
                    ? ""
                    : element["domiciliario_nombres"];
                name +=
                  element["domiciliario_apellidos"] == null
                    ? ""
                    : " " + element["domiciliario_apellidos"];
                var dom = {
                  agendacion: element["agendacion"],
                  estado:
                    element["status"] == 2
                      ? "blue"
                      : element["videos"].length != 0 ||
                        element["imagenes"].length != 0
                      ? "orange"
                      : element["status"] == 1
                      ? "green"
                      : element["status"] == 0
                      ? "red"
                      : "",
                  textStatus:
                    element["videosEntrega"].length != 0 ||
                    element["imagenesEntrega"].length != 0
                      ? "Finalizado"
                      : element["videos"].length != 0 ||
                        element["imagenes"].length != 0
                      ? "Recogido"
                      : element["status"] == 1
                      ? "Tomado"
                      : "Sin tomar",
                  cliente: element["cliente_nombre"],
                  tel: element["cliente_telefono"],
                  direccion:
                    element["direccion_recogida"][0]["ciudad"] +
                    ", " +
                    element["direccion_recogida"][0]["indicaciones"],
                  direccionEntrega:
                    element["direcciones"].length == 0
                      ? ""
                      : element["direcciones"][0]["indicaciones"],
                  zona: element["zona"],
                  nameDomi: name,
                  pendiente: element["pendiente"],
                  confianza: element["confianza"],
                  videos: element["videos"],
                  info: element,
                };
                listaDomicilios.data.push(dom);
              });
              listaDomicilios.isNull = false;
            } else {
              listaDomicilios.isNull == true;
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    return {
      dateI,
      dateF,
      listaDomicilios,
      myLocale: {
        /* starting with Sunday */
        days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
        daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
        months:
          "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
            "_"
          ),
        monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split(
          "_"
        ),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: "dias",
      },
    };
  },
};
</script>

<style>
</style>