<template>
  <q-card class="bg-white text-black" style="width: 1100px; max-width: 80vw">
    <q-bar class="bg-primary">
      <q-space></q-space>
      <q-btn
      text-color="white"
        dense
        flat
        icon="close"
        v-close-popup
        @click="$emit('closePopupDomiciliario', false)"
      >
        <q-tooltip class="bg-white text-primary">Cerrar</q-tooltip>
      </q-btn>
    </q-bar>

    <div class="row q-ma-lg positionTop">
      <div class="col-6">
        <h4 class="text-subtitle1 text-primary">
          Listado de domiciliarios del día {{ fecha }}:
        </h4>
        <div v-if="!listas.isSearch">
          <div v-if="listas.domiciliarios.length != 0">
            <table class="tableCobros">
              <tr>
                <th>Nombre</th>
                <th># domis finalizados</th>
              </tr>
              <tr v-for="i in listas.domiciliarios" :key="i">
                <td>
                  {{ Object.keys(i).toString() }}
                </td>
                <td>{{ Object.values(i).toString() }}</td>
              </tr>
            </table>
          </div>
          <div v-else>No se finalizaron domicilios este día.</div>
          <q-input v-model="price" label="Precio del día *">
            <template v-slot:append>
              <q-icon
                v-if="price !== ''"
                name="check"
                @click="save()"
                class="cursor-pointer"
              >
              </q-icon>
            </template>
          </q-input>
        </div>
      </div>
    </div>

    <q-card-actions>
      <q-space></q-space>
      <q-btn color="grey" v-close-popup flat label="Cancelar" />
    </q-card-actions>
  </q-card>
</template>

<script>
import {userUtils, host} from "@/tools/index";
import axios from "axios";
import { useQuasar } from "quasar";
import { reactive, ref } from "vue";
export default {
  name: "verCobroDia",
  props: {
    fecha: String,
    precio: Int16Array,
    id: String,
  },

  emits: ["consultarCobros"],

  setup(props, { emit }) {
    const $q = useQuasar();
    const listas = reactive({
      domiciliarios: null,
      isSearch: false,
    });

    const price = ref(props.precio);
    getInfo();

    function getInfo() {
      console.log(props.fecha);
      listas.isSearch = true;
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("dia", props.fecha);
        axios
          .post(host + "reporteDia", fd)
          .then((result) => {
            listas.isSearch = false;
            if (result.data.rta != "OK") {
              $q.notify({ type: "positive", message: result.data.text });
            } else {
              listas.domiciliarios = result.data.lista;
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    function save() {
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("id", props.id);
        fd.append("precio", price.value);
        axios
          .post(host + "actualizaCobro", fd)
          .then((result) => {
            if (result.data.rta != "OK") {
              $q.notify({ type: "positive", message: result.data.text });
            } else {
              emit("consultarCobros");
              $q.notify({
                type: "positive",
                message: "Se ajusto el cobro correctamente",
              });
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    return {
      getInfo,
      save,
      listas,
      price,
    };
    // dateI.value = moment(dat).add(-1, "days").format("YYYY/MM/DD");
  },
};
</script>

<style scoped>
.tableCobros,
td {
  padding: 3px !important;
  border: 1px solid;
  border-color: grey;
  text-align: center !important;
}

th {
  padding: 5px !important;
  border: 1px solid;
  border-color: grey;
  text-align: start !important;
}

.tableCobros {
  border-collapse: collapse;
}
</style>