<template>
  <div class="fixed-center" v-if="listaDomicilios.isSearch">
    <q-circular-progress
      indeterminate
      size="50px"
      color="orange"
      class="q-ma-md"
    ></q-circular-progress>
  </div>
  <q-table
    v-else
    dense
    flat
    bordered
    rows-per-page-label="Items por pgna"
    separator="cell"
    title="Domicilios hoy"
    :rows="listaDomicilios.data"
    :columns="columns"
    row-key="agendacion"
    :pagination="initialPagination"
    :visible-columns="visibleColumns"
    no-data-label="Sin información"
    :filter-method="myfiltermethod"
    :filter="filter"
  >
    <template v-slot:top-right>
      <q-btn
        round
        color="primary"
        class="q-mr-md"
        icon="refresh"
        @click="refresh"
      ></q-btn>
      <q-input
        borderless
        dense
        debounce="300"
        v-model="filter"
        placeholder="Buscar"
      >
        <template v-slot:append>
          <q-icon name="search"></q-icon>
        </template>
      </q-input>
    </template>

    <template v-slot:bottom-row>
      <q-tr>
        <q-td colspan="100%">
          <div v-if="$q.screen.gt.xs" class="col q-mt-md">
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="numberDomi"
              label="#"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="agendacion"
              label="fecha"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="estado"
              label="estado"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="cliente"
              label="Cliente"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="tel"
              label="Tel"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="direccion"
              label="D.Recogida"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="zona"
              label="Z.entrega"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="direccionEntrega"
              label="D.Entrega"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="nameDomi"
              label="Domiciliario"
            ></q-toggle>
            <q-toggle
              v-model="visibleColumns"
              color="primary"
              val="confianza"
              label="Confi"
            ></q-toggle>
          </div>
          <!-- la ida es guardar esto en un staore -->
          <q-select
            v-else
            v-model="visibleColumns"
            multiple
            borderless
            dense
            options-dense
            :display-value="$q.lang.table.columns"
            emit-value
            map-options
            :options="columns"
            option-value="name"
            style="min-width: 150px"
          ></q-select>
        </q-td>
      </q-tr>
    </template>

    <template v-slot:body-cell-numberDomi="props">
      <q-td key="numberDomi">
        {{ props.row.numberDomi }}
      </q-td>
    </template>

    <template v-slot:body-cell-agendacion="props">
      <q-td key="agendacion">
        {{ $filters.timeAprox(props.row.agendacion) }}
      </q-td>
    </template>

    <template v-slot:body-cell-estado="props">
      <q-td key="estado">
        <q-chip
          size="sm"
          clickable
          :color="props.row.estado"
          text-color="white"
          dense
          class="text-weight-bolder"
          square
          >{{ props.row.textStatus }}</q-chip
        >
      </q-td>
    </template>

    <template v-slot:body-cell-ver="props">
      <q-td key="ver">
        <q-btn size="xs" dense color="accent" @click="verDomi(props.row.info)">
          Ver</q-btn
        >
      </q-td>
    </template>
    <template v-slot:body-cell-confianza="props">
      <q-td key="confianza">
        <q-icon
          size="10px"
          :color="props.row.confianza == true ? 'green' : 'grey'"
          name="circle"
        >
        </q-icon>
      </q-td>
    </template>
  </q-table>
  <q-dialog
    :maximized="maximizedToggle"
    transition-show="slide-up"
    transition-hide="slide-down"
    persistent
    v-model="domi.openPopupDomi"
  >
    <domicilio-component
      v-if="domi.openPopupDomi"
      @close-popup-new-base="closeP($event)"
      :historial="domi.historial"
      :info="domi.data"
    ></domicilio-component>
  </q-dialog>
</template>

<script>
import { reactive, ref } from "vue";
import { useQuasar } from "quasar";
import axios from "axios";
import { userUtils, host } from "@/tools/index";
import DomicilioComponent from "./TheDelivery.vue";
import moment from "moment";
import { onMounted } from "vue";

// import { onMounted } from "vue";

export default {
  name: "domiciliosLista",
  props: { dataLista: Object, historial: Boolean },
  components: { DomicilioComponent },

  setup(props) {
    const $q = useQuasar();
    const dateI = ref("");

    onMounted(() => {
      var dat = new Date();
      dateI.value = moment(dat).format("YYYY/MM/DD");
      initial();
    });

    const domi = reactive({
      openPopupDomi: false,
      data: [],
      historial: false,
    });

    const listaDomicilios = reactive({
      infoDomi: [],
      data: [],
      isSearch: false,
    });
    const columns = ref([
      {
        label: "#",
        name: "numberDomi",
        field: "numberDomi",
        sortable: true,
      },
      {
        label: "Fecha",
        name: "agendacion",
        field: "agendacion",
        sortable: true,
      },
      {
        label: "Estado",
        name: "estado",
        field: "estado",
        sortable: true,
      },
      {
        label: "Cliente",
        name: "cliente",
        field: "cliente",
        // field: row => row.nombres,
        sortable: true,
      },
      {
        label: "Tel",
        name: "tel",
        field: "tel",
        sortable: true,
      },
      {
        label: "D.Recogida",
        name: "direccion",
        field: "direccion",
        sortable: true,
      },
      {
        label: "Z.Entrega",
        name: "zona",
        field: "zona",
        sortable: true,
      },
      {
        label: "D.Entrega",
        name: "direccionEntrega",
        field: "direccionEntrega",
        sortable: true,
      },
      {
        label: "Domiciliarío",
        name: "nameDomi",
        field: "nameDomi",
        sortable: true,
      },
      {
        label: "Confi",
        name: "confianza",
        field: "confianza",
        sortable: true,
      },
      {
        label: "Abrir",
        name: "ver",
        field: "ver",
        sortable: true,
      },
    ]);

    function initial() {
      if (props.historial) {
        cargarHistorial();
      } else {
        domi.historial = false;
        cargueDomis();
      }
    }

    function refresh() {
      cargueDomis();
    }

    function cargarHistorial() {
      listaDomicilios.data = props.dataLista;
      domi.historial = true;
      listaDomicilios.isSearch = false;
    }

    function cargueDomis() {
      listaDomicilios.data = [];
      userUtils.getToken().then((token) => {
        listaDomicilios.isSearch = true;
        const fd = new FormData();
        fd.append("token", token);
        axios
          .post(host + "listaDomicilios", fd)
          .then((result) => {
            listaDomicilios.isSearch = false;
            if (result.data.rta.length != 0) {
              var info = result.data.rta;
              info.forEach((element) => {
                var name =
                  element["domiciliario_nombres"] == null
                    ? ""
                    : element["domiciliario_nombres"];
                name +=
                  element["domiciliario_apellidos"] == null
                    ? ""
                    : " " + element["domiciliario_apellidos"];
                var dom = {
                  id: element["_id"],
                  numberDomi: element["numberDomi"],
                  agendacion: element["agendacion"],
                  estado:
                    element["status"] == 2
                      ? "blue"
                      : element["videos"].length != 0 ||
                        element["imagenes"].length != 0
                      ? "orange"
                      : element["status"] == 1
                      ? "green"
                      : element["status"] == 0
                      ? "red"
                      : "",
                  textStatus:
                    element["videosEntrega"].length != 0 ||
                    element["imagenesEntrega"].length != 0
                      ? "Finalizado"
                      : element["videos"].length != 0 ||
                        element["imagenes"].length != 0
                      ? "Recogido"
                      : element["status"] == 1
                      ? "Tomado"
                      : "Sin tomar",
                  cliente: element["cliente_nombre"],
                  tel: element["cliente_telefono"],
                  direccion:
                    element["direccion_recogida"][0]["ciudad"] +
                    ", " +
                    element["direccion_recogida"][0]["indicaciones"],
                  direccionEntrega:
                    element["direcciones"].length == 0
                      ? ""
                      : element["direcciones"][0]["indicaciones"],
                  zona: element["zona"],
                  nameDomi: name,
                  confianza: element["confianza"],
                  videos: element["videos"],
                  info: element,
                };
                listaDomicilios.data.push(dom);
              });
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    function verDomi(info) {
      domi.data = info;
      domi.openPopupDomi = true;
    }

    function closeP() {
      cargueDomis();
      domi.openPopupDomi = false;
    }

    function myfiltermethod(rows, term, cols) {
      const lowerSearch = term ? term.toLowerCase() : "";
      const filt = rows.filter((row) => {
        let s1 = true;
        let ans = false;
        var element = [];
        for (let s = 0; s < cols.length; s++) {
          element.push(cols[s]["field"]);
        }
        if (lowerSearch != "") {
          s1 = false;
          let values = Object.values(row);
          let keys = Object.keys(row);
          let lower = values.map((x, i) => {
            var element = [];
            for (let s = 0; s < cols.length; s++) {
              element.push(cols[s]["field"]);
            }
            if (x != null && element.includes(keys[i])) {
              return x.toString().toLowerCase();
            }
          });
          for (let val = 0; val < lower.length; val++) {
            if (lower[val] != null) {
              if (lower[val].includes(lowerSearch)) {
                s1 = true;
                break;
              }
            }
          }
        }
        ans = false;
        if (s1) {
          ans = true;
        }
        return ans;
      });
      console.log(filt);
      return filt;
    }

    return {
      listaDomicilios,
      columns,
      domi,
      maximizedToggle: ref(true),
      visibleColumns: ref([
        "numberDomi",
        "fecha",
        "agendacion",
        "cliente",
        "tel",
        "estado",
        "nameDomi",
        "direccion",
        "zona",
        "direccionEntrega",
        "direccion",
        "confianza",
        "ver",
      ]),
      initialPagination: {
        rowsPerPage: 15,
      },
      dateI,
      filter: ref(""),
      refresh,
      myfiltermethod,
      closeP,
      verDomi,
    };
  },
};
</script>

<style scoped>
th,
td {
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
}
.q-table--dense,
.q-table td {
  padding: 0px 4px !important;
}
</style>