<template>
  <div class="q-ma-md">
    <div class="column">
      <span class="text-subtitle1 fat col"
        ><strong>AGREGAR </strong> cliente</span
      >
    </div>
    <div class="row">
      <div class="col-6 q-px-md">
        <q-input
          v-model="cliente.nombre"
          :dense="true"
          label="Nombre cliente *"
        >
          <template v-slot:append>
            <q-icon
              v-if="cliente.nombre !== ''"
              name="close"
              @click="clear()"
              class="cursor-pointer"
            >
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-3 q-px-md">
        <q-input
          :dense="true"
          ref="inputTelefono"
          v-model="cliente.tel"
          label="Teléfono cliente"
        >
          <template v-slot:append>
            <q-icon
              v-if="cliente.tel !== ''"
              name="close"
              @click="cliente.tel = ''"
              class="cursor-pointer"
            ></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-3 q-px-md">
        <q-input
          :dense="true"
          v-model="cliente.whatsapp"
          label="WhatsApp cliente"
        >
          <template v-slot:append>
            <q-icon
              v-if="cliente.whatsapp !== ''"
              name="close"
              @click="cliente.whatsapp = ''"
              class="cursor-pointer"
            ></q-icon>
          </template>
        </q-input>
      </div>
    </div>
    <div class="q-py-md">
      <span class="text-subtitle1 q-mt-md"> Dirección</span>
      <div class="row">
        <div class="col-6 q-px-md">
          <q-select
            :dense="true"
            :options="['Bucaramanga', 'Floridablanca', 'Gíron', 'Piedecuesta']"
            bottom-slots
            v-model="cliente.direccion.municipio"
            label="Municipio *"
          >
          </q-select>
        </div>
        <div class="col-6 q-px-md">
          <q-input
            :dense="true"
            bottom-slots
            v-model="cliente.direccion.observaciones"
            label="Dirección "
          >
          </q-input>
        </div>
      </div>
      <q-btn
        class="col"
        size="small"
        :color="isSending ? 'grey' : 'accent'"
        text-color="white"
        label="Guardar"
        @click="guardarCli()"
      ></q-btn>
    </div>
  </div>
  <q-separator></q-separator>
  <div class="q-ma-md">
    <div class="column">
      <span class="text-subtitle1 col fat">
        <strong>EDITAR</strong> cliente</span
      >
    </div>
    <div class="row">
      <div class="col-12 q-px-md">
        <q-select
          :dense="true"
          use-input
          @filter="fetchOptions"
          @keyup.enter="changeToE"
          @keydown.tab.prevent="changeToE"
          @update:model-value="updateSearch"
          :loading="listas.loading"
          input-debounce="400"
          :options="listas.clientes"
          option-label="nombres"
          option-value="id"
          label="Buscar cliente (nombre o télefono)"
        >
          <template v-slot:append>
            <q-icon
              v-if="clienteEdit.nombre !== ''"
              name="close"
              @click="clienteEdit.nombre = ''"
              class="cursor-pointer"
            >
            </q-icon>
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-italic text-grey">
                Sin resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="col-6 q-px-md">
        <q-input
          v-model="clienteEdit.nombre"
          :dense="true"
          label="Buscar cliente *"
        >
          <template v-slot:append>
            <q-icon
              v-if="clienteEdit.nombre !== ''"
              name="close"
              @click="clear()"
              class="cursor-pointer"
            >
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-3 q-px-md">
        <q-input
          :dense="true"
          ref="inputTelefono"
          v-model="clienteEdit.tel"
          label="Teléfono cliente"
        >
          <template v-slot:append>
            <q-icon
              v-if="clienteEdit.tel !== ''"
              name="close"
              @click="clienteEdit.tel = ''"
              class="cursor-pointer"
            ></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-3 q-px-md">
        <q-input
          :dense="true"
          v-model="clienteEdit.whatsapp"
          label="WhatsApp cliente"
        >
          <template v-slot:append>
            <q-icon
              v-if="clienteEdit.whatsapp !== ''"
              name="close"
              @click="clienteEdit.whatsapp = ''"
              class="cursor-pointer"
            ></q-icon>
          </template>
        </q-input>
      </div>
    </div>
    <div class="q-py-md">
      <span class="text-subtitle1 q-mt-md"> Direcciónes registradas </span>
      <span>
        * si desea editar una dirección, seleccionela en las opciones y luego
        precione en el boton "editar"
      </span>
      <div v-if="listas.direcciones.length != 0" class="col-12 q-px-md">
        <q-select
          :dense="true"
          v-show="inputDir"
          ref="inputDirecciones"
          v-model="clienteEdit.direccionId"
          :options="listas.direcciones"
          @update:model-value="editDir()"
          option-label="direccion"
          option-value="id"
          label="Direcciones guardadas"
        >
          <template v-slot:append>
            <q-icon
              v-if="clienteEdit.direccionId !== ''"
              name="close"
              @click="clienteEdit.direccionId = ''"
              class="cursor-pointer"
            ></q-icon> </template
        ></q-select>
      </div>
      <div class="row">
        <div class="col-6 q-px-md">
          <q-select
            :dense="true"
            :options="['Bucaramanga', 'Floridablanca', 'Gíron', 'Piedecuesta']"
            bottom-slots
            v-model="clienteEdit.direccion.municipio"
            label="Municipio *"
          >
          </q-select>
        </div>
        <div class="col-6 q-px-md">
          <q-input
            :dense="true"
            bottom-slots
            v-model="clienteEdit.direccion.observaciones"
            label="Dirección "
          >
          </q-input>
        </div>
      </div>
      <q-btn
        class="col"
        size="small"
        :color="isSending ? 'grey' : 'accent'"
        text-color="white"
        label="Editar"
        @click="editCli()"
      ></q-btn>
       <q-btn
        class="col q-ml-xs"
        size="small"
        :color="isSending ? 'grey' : 'red'"
        text-color="white"
        label="Borrar"
        @click="deleteCliente()"
      ></q-btn>
    </div>
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import { reactive, ref, nextTick } from "vue";
import axios from "axios";
import { host, userUtils } from "@/tools/index";

export default {
  setup() {
    const $q = useQuasar();
    const isSending = ref(false);
    const inputTelefono = ref(null);
    const inputTel = ref(false);
    const inputDirecciones = ref(null);
    const inputDir = ref(false);
    const listas = reactive({
      loading: false,
      clientes: [],
      direcciones: [],
    });
    const cliente = reactive({
      nombre: "",
      whatsapp: "",
      tel: "",
      direccion: {
        municipio: "",
        observaciones: "",
      },
    });

    const clienteEdit = reactive({
      buscar: "",
      nombre: "",
      whatsapp: "",
      tel: "",
      nombreId: "",
      direccionId: "",
      direccion: {
        municipio: "",
        observaciones: "",
      },
    });

    const fetchOptions = async (val, update) => {
      if (val === "") {
        update();
        return;
      } else if (val != "") {
        var by = isNaN(val) ? "nombres" : "numero";
        clienteEdit.buscar = val;
        listas.clientes = [];
        listas.loading = true;
        userUtils.getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append(by, val);
          axios
            .post(host + "buscaCliente", fd)
            .then((result) => {
              listas.loading = false;
              if (
                result.data.rta != "NOT FOUND" ||
                result.data.clientes.length != 0
              ) {
                var cli = result.data.clientes;
                for (let c = 0; c < cli.length; c++) {
                  const element = cli[c];
                  listas.clientes.push({
                    nombres: element.nombres,
                    id: element.id,
                    telefono: element.telefono,
                    whatsapp: element.whatsapp,
                    direcciones: element.direcciones,
                  });
                }
              }
            })
            .catch((error) => {
              isSending.value = false;
              $q.notify({ type: "negative", message: error.response.data.rta });
            });
        });

        update();
        return;
      }
    };

    function changeToE() {
      var by = isNaN(clienteEdit.buscar) ? "nombres" : "numero";
      if (by == "nombres") {
        clienteEdit.nombre = clienteEdit.buscar;
        clienteEdit.tel = "";
      } else {
        clienteEdit.nombre = "";
        clienteEdit.tel = clienteEdit.buscar;
      }
      inputTel.value = true;
      nextTick(() => {
        inputTelefono.value.focus();
      });
    }

    function updateSearch(a) {
      clienteEdit.buscar = a.nombres;
      clienteEdit.nombre = a.nombres;
      clienteEdit.tel = a.telefono;
      clienteEdit.whatsapp = a.whatsapp;
      asignarInfo(a);
    }

    function asignarInfo(info) {
      listas.direcciones = [];
      var data = listas.clientes.find(
        (e) => e.nombres == info.nombres || e.telefono == info.telefono
      );
      clienteEdit.nombreId = data.id;
      inputTel.value = true;
      var direc = data.direcciones;
      for (let d = 0; d < direc.length; d++) {
        const element = direc[d];
        var indi =
          element["indicaciones"] == "null"
            ? ""
            : " - " + element["indicaciones"];
        listas.direcciones.push({
          id: element["id"],
          direccion: element["ciudad"] + indi,
        });
      }
      // clienteEdit.direccionId = listas.direcciones[0];
      inputDir.value = true;
      nextTick(() => {
        inputDirecciones.value.focus();
      });
    }

    function guardarCli() {
      if (isSending.value == false) {
        isSending.value = true;
        if (
          cliente.nombre &&
          cliente.tel &&
          cliente.direccion.municipio &&
          cliente.direccion.observaciones
        ) {
          userUtils.getToken().then((token) => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append("nombres", cliente.nombre);
            fd.append("telefono", cliente.tel);
            fd.append("whatsapp", cliente.whatsapp);
            fd.append("domiciliario", "");
            fd.append("lat", "");
            fd.append("lon", "");
            fd.append("ciudad", cliente.direccion.municipio);
            fd.append(
              "indicaciones",

              cliente.direccion.observaciones
            );
            fd.append("observaciones_admin", "");
            fd.append("precio", "");
            fd.append("destino", "");
            axios
              .post(host + "nuevoCliente", fd)
              .then((result) => {
                isSending.value = false;
                if (result.data.rta == "OK") {
                  clear();
                  $q.notify({
                    type: "positive",
                    message: "Cliente creado",
                  });
                } else {
                  $q.notify({
                    type: "negative",
                    message: "Error: no se puedo agregar este cliente",
                  });
                }
              })
              .catch((error) => {
                isSending.value = false;
                $q.notify({
                  type: "negative",
                  message: error.response.data.rta,
                });
              });
          });
        } else {
          isSending.value = false;
          $q.notify({ type: "warning", message: "Error: Faltan datos" });
        }
      }
    }

    function clear() {
      listas.clientes = [];
      listas.direcciones = [];
      cliente.nombre = "";
      cliente.tel = "";
      cliente.whatsapp = "";
      cliente.direccion.municipio = "";
      cliente.direccion.observaciones = "";
      inputTel.value = false;
      clienteEdit.buscar = "";
      clienteEdit.nombre = "";
      clienteEdit.whatsapp = "";
      clienteEdit.tel = "";
      clienteEdit.direccionId = "";
      clienteEdit.nombreId = "";
      clienteEdit.direccion.municipio = "";
      clienteEdit.direccion.observaciones = "";
    }

    function editDir() {
      var dirS = clienteEdit.direccionId["direccion"].split(" - ");
      clienteEdit.direccion.municipio = dirS[0];
      clienteEdit.direccion.observaciones = dirS[1];
    }

    function editCli() {
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("id", clienteEdit.nombreId);
        fd.append("nombres", clienteEdit.nombre);
        fd.append("telefono", clienteEdit.tel);
        fd.append("whatsapp", clienteEdit.whatsapp);
        axios
          .post(host + "editaCliente", fd)
          .then((result) => {
            isSending.value = false;
            if (result.data.rta == "OK") {
              clear();
              $q.notify({
                type: "positive",
                message: "Cliente editado",
              });
            } else {
              $q.notify({
                type: "negative",
                message: "Error: no se puedo editar este cliente",
              });
            }
          })
          .catch((error) => {
            isSending.value = false;
            $q.notify({
              type: "negative",
              message: error.response.data.rta,
            });
          });
      });
      if (clienteEdit.direccionId != "") {
        userUtils.getToken().then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("id", clienteEdit.direccionId["id"]);
          fd.append("ciudad", clienteEdit.direccion.municipio);
          fd.append("indicaciones", clienteEdit.direccion.observaciones);
          axios
            .post(host + "editaDireccion", fd)
            .then((result) => {
              isSending.value = false;
              if (result.data.rta == "OK") {
                clear();
                $q.notify({
                  type: "positive",
                  message: "Dirección editada",
                });
              } else {
                $q.notify({
                  type: "negative",
                  message: "Error: no se puedo editar esta direccion",
                });
              }
            })
            .catch((error) => {
              isSending.value = false;
              $q.notify({
                type: "negative",
                message: error.response.data.rta,
              });
            });
        });
      }
    }

    function deleteCliente() {
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("id", clienteEdit.nombreId);
        axios
          .post(host + "eliminaCliente", fd)
          .then((result) => {
            isSending.value = false;
            if (result.data.rta == "OK") {
              clear();
              $q.notify({
                type: "positive",
                message: "Cliente eliminado",
              });
            } else {
              $q.notify({
                type: "negative",
                message: "Error: no se puedo eliminar este cliente",
              });
            }
          })
          .catch((error) => {
            isSending.value = false;
            $q.notify({
              type: "negative",
              message: error.response.data.rta,
            });
          });
      });

    }

    return {
      cliente,
      clienteEdit,
      listas,
      fetchOptions,
      guardarCli,
      editCli,
      deleteCliente,
      editDir,
      updateSearch,
      changeToE,
      inputDirecciones,
      inputDir,
      inputTelefono,
      inputTel,
      isSending,
    };
  },
};
</script>

<style>
</style>