<template>
  <div class="q-ma-md">
    <div class="row">
      <div class="col-5 borderCard">
        <GeneralConfiguration/>
      </div>
       <div class="col-7">
        <BasesConfiguration/>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralConfiguration from '../Configurations/GeneralConfiguration.vue';
import BasesConfiguration from '../Configurations/BasesConfiguration.vue';
export default {
  components: {
    GeneralConfiguration, 
    BasesConfiguration,
  }
}
</script>

<style>

.borderCard{
  /* padding: 10px; 
  border: 1px red solid; */
}
</style>