<template>
  <q-card class="bg-white text-black" style="width: 1100px; max-width: 80vw;">
    <q-bar class="bg-primary">
      <q-space></q-space>
      <q-btn
        dense
        flat
        text-color="white"
        icon="close"
        v-close-popup
        @click="$emit('closePopupDomiciliario', false)"
      >
        <q-tooltip class="bg-white text-primary">Cerrar</q-tooltip>
      </q-btn>
    </q-bar>
    <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
        <q-tab name="info" label="Información general"></q-tab>
        <q-tab name="soportes" label="Soportes"></q-tab>
        <q-tab name="historial" label="Historial"></q-tab>
      </q-tabs>
    <q-separator></q-separator>
    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="info">
        <div class="text-h6">
          <GeneralInfo @close-popup-domiciliario="$emit('close-popup-domiciliario', true)" :domi="info"></GeneralInfo>
        </div>
      </q-tab-panel>
      <q-tab-panel name="soportes">
        <div class="text-h6">
          <SoportesInfo :domi="info"></SoportesInfo>
        </div>
      </q-tab-panel>
      <q-tab-panel name="historial">
        <div class="text-h6">
          <DomiciliarioHisto :id="info.url"></DomiciliarioHisto>
        </div>
      </q-tab-panel>
    </q-tab-panels>

    <q-card-actions>
      <q-space></q-space>
        <q-btn color="red" flat @click="dialog = true" label="Eliminar Domiciliario" />
        <q-btn color="grey" v-close-popup flat label="Cancelar" />
    </q-card-actions>
  </q-card>
  <q-dialog v-model="dialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="cancel" color="primary" text-color="white"></q-avatar>
          <span class="q-ml-sm">¿Deseas eliminar este domiciliario?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cerrar" color="primary" v-close-popup></q-btn>
          <q-btn
            flat
            label='SI' 
            color="primary"
            @click="eliminarDomiciliario"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  
    <q-dialog v-model="dialogV" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="cancel" color="primary" text-color="white"></q-avatar>
          <span class="q-ml-sm"><strong>¿Seguro que deseas vincular estas cuentas?, verifica la información! </strong> </span>
          <span class="q-ml-sm">* Se eliminará la cuenta seleccionada y los datos pasaran al usuario {{ info["nombres"] + " " + info["apellidos"] }}</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cerrar" color="primary" v-close-popup></q-btn>
          <q-btn
            flat
            label='SI' 
            color="primary"
            @click="vincular()"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  
</template>

<script>
import { ref } from "@vue/reactivity";
import { host, userUtils } from '@/tools/index';

import GeneralInfo from "./GeneralInfo.vue";
import DomiciliarioHisto from "./HistoryDeliveryMan.vue";
import SoportesInfo from "./SoportesInfo.vue";
import axios from "axios";
import { useQuasar } from "quasar";



export default {
  components:{DomiciliarioHisto, GeneralInfo, SoportesInfo },

  name: "DomiciliarioC",

  props: {
    info: Object,
  },

  emits: ["closePopupDomiciliario"],

  setup(props,{emit }) {

    console.log(props)
    const tab = ref('info');
    const dialog = ref(false);
    const dialogV = ref(false);
    const $q = useQuasar();

    function eliminarDomiciliario() {
      if (props.info.url) {
        var splitURL = props.info.url.split('/')
          var id = splitURL.slice(-2)[0]
        }
       userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("domiciliarioId", id);
        fd.append("tipo",  "borrar");
        axios
          .post(host + "eliminaMico", fd)
          .then((result) => {
            console.log(result);
            if (result.data.rta != "OK") {
              $q.notify({ type: "negative", message: result.data.rta });
            } else {
              dialog.value = false;
              emit('close-popup-domiciliario', true)
            }
          })
          .catch((error) => {
            $q.notify({ type: "negative", message: error.response.data.rta });
          });
      });
    } 
    
   
    return {
      tab,
      eliminarDomiciliario,
      dialog,
      dialogV
    };
  },
};
</script>

<style lang="scss" scoped>
.positionTop {
  align-items: top !important ;
}
</style>