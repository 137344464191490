<template>
  <span>Nivel de prioridad</span>
  <div class="row">
    <div class="col q-mx-sm">
      <q-select dense v-model="primero" :options="options" label="1ro"></q-select>
    </div>
     <div class="col q-mx-sm">
      <q-select dense v-model="segundo" :options="options" label="2do"></q-select>
    </div>
     <div class="col q-mx-sm">
      <q-select dense v-model="tercero" :options="options" label="3ro"></q-select>
    </div>
    <div class="col-1 q-mx-sm">
      <q-btn
        flat
        round
        icon="save"
        @click="savePriority"
        >
      </q-btn>
    </div>
  </div>
</template>

<script>
import { configUtils, host, userUtils } from '@/tools';
import axios from 'axios';
import { reactive, ref, onMounted } from 'vue';

// eslint-disable-next-line no-unused-vars


export default {
  setup() {
    const primero = ref('');
    const segundo = ref('');
    const tercero = ref('');
    const options = reactive(['Nuevo', 'Base', 'Cercano'])

    onMounted(async () => {
      await configUtils.getConfigs().then((rta) => {
        primero.value = rta.find(e => e.nombre == "ORDEN1")["valor"];
        segundo.value = rta.find(e => e.nombre == "ORDEN2")["valor"];
        tercero.value = rta.find(e => e.nombre == "ORDEN3")["valor"];
      });
    });


    function savePriority() {
      userUtils.getToken().then((token) => {
        const fp = new FormData();
        fp.append("tipo", "set_valor");
        fp.append("token", token);
        fp.append('config', 'ORDEN1'),
          fp.append('valor', primero.value + ',' + segundo.value + ',' + tercero.value),
          axios.post(host + "configuraciones", fp).then((rta) => {
            console.log(rta)
          });
      });
    }
  
    return {
      savePriority,
      primero,
      segundo,
      tercero,
      options
    }
  },
}
</script>