<template>
  <div id="full_page" class="fixed-center">
    <q-card>
      <q-card-section>
        <div class="text-h6">Mansajería Alianza</div>
      </q-card-section>
      <q-tabs v-model="tab" class="text-primary">
        <q-tab label="Inicio de sesión" name="one"></q-tab>
        <q-tab label="Registro" name="two"></q-tab>
      </q-tabs>
      <q-separator></q-separator>
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel class="q-gutter-md" name="one">
          <q-input
            outlined
            v-model="loginInfo.correo"
            type="email"
            label="Correo"
          ></q-input>
          <q-input
            v-model="loginInfo.contrasena"
            @keyup.enter="text == 'Entrar' ? doLogin() : doSingup()"
            outlined
            :type="loginInfo.isPwd ? 'password' : 'text'"
            label="Contraseña"
          >
            <template v-slot:append>
              <q-icon
                :name="loginInfo.isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="loginInfo.isPwd = !loginInfo.isPwd"
              ></q-icon>
            </template>
          </q-input>
        </q-tab-panel>
        <q-tab-panel class="q-gutter-md" name="two">
          <q-input outlined v-model="registro.correo" label="Correo"></q-input>
          <q-input
            v-model="registro.contrasena"
            outlined
            :type="registro.isPwd ? 'password' : 'text'"
            label="Contraseña"
          >
            <template v-slot:append>
              <q-icon
                :name="registro.isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="registro.isPwd = !registro.isPwd"
              ></q-icon>
            </template>
          </q-input>
          <q-input
            v-model="registro.cContrasena"
            @keyup.enter="text == 'Entrar' ? doLogin() : doSingup()"
            outlined
            :type="registro.isPwd ? 'password' : 'text'"
            label="Confirmación contraseña"
          >
            <template v-slot:append>
              <q-icon
                :name="registro.isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="registro.isPwd = !registro.isPwd"
              ></q-icon>
            </template>
          </q-input>
        </q-tab-panel>
      </q-tab-panels>
      <q-card-actions align="right">
        <q-btn flat @click="text == 'Entrar' ? doLogin() : doSingup()">{{
          text
        }}</q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import { ref } from "vue";
import {authUtils, userUtils} from "@/tools/index";
import { useQuasar } from "quasar";
// import { useRouter } from 'vue-router';
import router from "@/router/index";

export default {
  watch: {
    tab: {
      handler(newValue) {
        if (newValue == "two") this.text = "Registrar";
      },
    },
  },

  setup() {
    const $q = useQuasar();

    const registro = ref({
      correo: "",
      contraseña: "",
      cContrasena: "",
      isPwd: true,
    });

    const loginInfo = ref({
      correo: "",
      contrasena: "",
      isPwd: true,
    });

    irA();

    function irA() {
      router.push({ name: "home" });
    }

    async function doLogin() {
      if (this.loginInfo.contrasena && this.loginInfo.correo) {
        await authUtils.login(this.loginInfo.correo, this.loginInfo.contrasena)
          .then((rta) => {
            if (rta) {
              userUtils.valida(rta.user.accessToken).then((a) => {
                console.log(a);
                if (a) {
                  router.push({ name: "home" });
                } else {
                  router.push({ name: "login" });
                }
              });
            }
          });
      } else {
        $q.notify({
          type: "warning",
          message: "Error: Debes llenar toda la información",
        });
      }
    }

    async function doSingup() {
      if (this.registro.contrasena == this.registro.cContrasena) {
        if (this.registro.correo && this.registro.cContrasena) {
          await authUtils
            .singup(this.registro.correo, this.registro.cContrasena)
            .then((rta) => {
              if (rta) {
                userUtils.valida(rta.user.accessToken).then((a) => {
                  console.log(a);
                  if (a) {
                    router.push({ name: "home" });
                  } else {
                    router.push({ name: "login" });
                  }
                });
              }
            });
        } else {
          $q.notify({
            type: "warning",
            message: "Error: Debes llenar toda la información",
          });
        }
      } else {
        $q.notify({
          type: "warning",
          message: "Error: Las contraseñas no coinciden",
        });
      }
    }

    return {
      tab: ref("one"),
      text: ref("Entrar"),
      registro,
      loginInfo,
      doLogin,
      doSingup,
    };
  },
};
</script>


<style lang="scss" scoped>
#full_page {
  width: 50%;
  text-align: center;
}
</style>
