<template>
  <div class="row">
    <div class="col-3 q-px-md">
      <q-select
        use-input
        hide-selected
        fill-input
        v-model="historial.domiId"
        @filter="fetchOptionsDomi"
        @input-value="updateSearchDomi"
        :loading="historial.loading"
        prepend-icon="search"
        append-icon="close"
        input-debounce="400"
        :options="historial.backupDomiciliarios"
        option-label="nombres"
        option-value="id"
        label="Domiciliario"
      >
        <template v-slot:prepend>
          <q-icon name="refresh" @click="cargueDomis()" class="cursor-pointer">
          </q-icon>
        </template>
        <template v-slot:append>
          <q-icon
            v-if="historial.domiId !== ''"
            name="close"
            @click="historial.domiId = null"
            class="cursor-pointer"
          >
          </q-icon>
        </template>
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey"> Sin resultados </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <div class="col-3 q-ma-md">
      <q-input :dense="true" v-model="dateI" mask="date" :rules="['dateI']">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :locale="myLocale"
                color="primary"
                minimal
                v-model="dateI"
              >
                <div class="row items-center justify-end">
                  <q-btn
                    v-close-popup
                    label="Cerrar"
                    color="primary"
                    flat
                  ></q-btn>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <div class="col-3 q-ma-md">
      <q-input :dense="true" v-model="dateF" mask="date" :rules="['dateF']">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :locale="myLocale"
                minimal
                color="primary"
                v-model="dateF"
              >
                <div class="row items-center justify-end">
                  <q-btn
                    v-close-popup
                    label="Cerrar"
                    color="primary"
                    flat
                  ></q-btn>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>

    <div class="row q-my-md q-mx-md" v-if="historial.data.length != 0">
      <q-table
        flat
        bordered
        dense
        separator="cell"
        :rows="historial.data"
        rows-per-page-label="Items por pgna"
        :columns="columns"
        :visible-columns="visibleColumns"
        no-data-label="Sin información"
        :pagination="initialPagination"
      >
        <template v-slot:body-cell-dia="props">
          <q-td key="dia">
            {{ $filters.timeAprox(props.row.dia) }}
          </q-td>
        </template>
        <template v-slot:body-cell-dineroDomis="props">
          <q-td key="dineroDomis">
            {{ $filters.money(props.row.dineroDomis) }}
          </q-td>
        </template>
        <template v-slot:body-cell-horasPerdidas="props">
          <q-td :props="props" key="horasPerdidas">
            <q-badge :color="getColor(props.row.horasPerdidas)">
              {{ props.row.horasPerdidas }}
            </q-badge>
          </q-td>
        </template>
        <template v-slot:body-cell-horaInicio="props">
          <q-td :props="props" key="horasPerdidas">
              {{ props.row.horaInicio === null ? '': $filters.timeAprox(props.row.horaInicio) }}
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script >
import { hostD, hostDRF, userUtils } from "@/tools";
import axios from "axios";
import { useQuasar } from "quasar";
import { onMounted, reactive, watch, ref } from "vue";
import moment from "moment";

export default {
  setup() {
    const $q = useQuasar();

    const historial = reactive({
      data: [],
      isSearch: false,
      listaDomiciliarios: [],
      backupDomiciliarios: [],
      loading: false,
      domiId: null,
    });

    const dateI = ref("");
    const dateF = ref("");

    const columns = ref([
      {
        label: "Día",
        name: "dia",
        field: "dia",
        sortable: true,
      },
      {
        label: "Hora activación",
        name: "horaInicio",
        field: "horaInicio",
        sortable: true,
      },
      {
        label: "# domicilios",
        name: "cantidadDomis",
        field: "cantidadDomis",
        sortable: true,
      },
      {
        label: "Dinero",
        name: "dineroDomis",
        field: "dineroDomis",
        sortable: true,
      },
      {
        label: "Horas trabajadas",
        name: "horasTrabajadas",
        field: "horasTrabajadas",
        sortable: true,
      },
      {
        label: "Horas en Base",
        name: "horasBase",
        field: "horasBase",
        sortable: true,
      },
      {
        label: "Horas perdidas",
        name: "horasPerdidas",
        field: "horasPerdidas",
        align: "right",
        sortable: true,
      },

      {
        label: "Hora del ultimo servicio",
        name: "horaUltimo",
        field: "horaUltimo",
        sortable: true,
      },
    ]);

    watch(
      () => historial.domiId,
      (newId, prevId) => {
        if (newId !== prevId && newId?.id != null) {
          cargueData();
        }
      }
    );

    watch([dateI, dateF], ([newA, newB]) => {
      if (newA && newB && historial.domiId != null) {
        cargueData();
      }
    });

    onMounted(() => {
      var dat = new Date();
      dateI.value = moment(dat).add(-6, "days").format("YYYY/MM/DD");
      dateF.value = moment(dat).format("YYYY/MM/DD");
      cargueDomis();
    });

    function cargueDomis() {
      historial.listaDomiciliarios = [];
      userUtils.getToken().then((token) => {
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .get(hostDRF + "domiciliarios/", {
            headers: headers,
            params: {
              page_size: 1000,
            },
          })
          .then((result) => {
            if (result.data.results.length != 0) {
              var data = result.data.results;
              for (let d = 0; d < data.length; d++) {
                const element = data[d];
                historial.listaDomiciliarios.push({
                  nombres: element["nombres"] + " " + element["apellidos"],
                  id: element["userId"],
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            $q.notify({ type: "negative", message: "error" });
          });
      });
    }

    async function cargueData() {
      if (historial.domiId !== null) {
        try {
          var i = moment(dateI.value).format("YYYY-MM-DD");
          var f = moment(dateF.value).format("YYYY-MM-DD");
          const horasT = await axios.get(
            `${hostD}horas_trabajadas/${historial.domiId.id}?fechaI=${i}&fechaF=${f}`
          );
          historial.data = horasT.data.info;
        } catch (error) {
          console.log(error);
          $q.notify({ type: "warning", message: error });
        }
      }
    }

    function fetchOptionsDomi(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        historial.backupDomiciliarios = historial.listaDomiciliarios.filter(
          (v) => v.nombres.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    }

    function updateSearchDomi(a) {
      if (a != "") {
        historial.domiId = a;
      } else {
        historial.domiId = null;
      }
    }

    function getColor(info) {
      const hours = info.split(":");
      if (parseInt(hours[0]) >= 7) {
        return "red";
      } else if (3 < parseInt(hours[0]) && parseInt(hours[0]) < 7) {
        return "orange";
      } else if (3 >= parseInt(hours[0])) {
        return "green";
      } else {
        return "red";
      }
    }

    return {
      cargueData,
      fetchOptionsDomi,
      updateSearchDomi,
      dateI,
      dateF,
      historial,
      columns,
      initialPagination: {
        rowsPerPage: 15,
      },
      visibleColumns: ref([
        "dia",
        "horaInicio",
        "horasTrabajadas",
        "horasPerdidas",
        "horasBase",
        "cantidadDomis",
        "dineroDomis",
        "horaUltimo",
      ]),
      getColor,
      myLocale: {
        /* starting with Sunday */
        days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
        daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
        months:
          "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
            "_"
          ),
        monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split(
          "_"
        ),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: "dias",
      },
    };
  },
};
</script>


<style>
</style>
