<template>
    <div class="row q-ma-none">
        <div class="col-12 q-ma-none">
            <span class="text-subtitle1 text-primary">
                Soportes domiciliario:
            </span>
            <div class="row">
                <div class="col-4" v-for="i in info.historial" :key="i">
                    <span class="text-subtitle1"> Soportes de {{ i["tipo"] }}: </span>
                    <q-img :src="i['foto']" width="300px"></q-img>
                </div>
            </div>
        </div> 
    </div>
</template>


<script>
import { hostDRF, userUtils } from "@/tools";
import { reactive } from "vue";
import axios from "axios";

export default {
    props: {
        domi: Object,
    },
    setup(props) { 

        const info = reactive({
            historial :[]
        })

        getHistory();

        function getHistory() {
            var splitURL = props.domi.url.split('/')
            var id = splitURL.slice(-2)[0]
            userUtils.getToken().then(token => {
                let headers = {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: token,
                }
                axios.get(hostDRF + `domiciliarios/${id}/soportes/`, {
                    headers: headers
                }).then(result => {
                    console.log(result)
                    info.historial = result.data;
                })

            })
            
        }
        return {
            info,
        }
    }
}

</script>