<template>
    <div class="column">
        <span class="text-subtitle1 "> Gestión de bases
         <q-btn icon="add" dense color="primary" class="q-mr-md" flat @click="openNewBase = !openNewBase">
             nueva base
        </q-btn>
        <q-btn round color="primary" icon="refresh"  class="cursor-pointer" @click="callBases()"></q-btn>
        
        </span>
        <q-list padding v-for="e, index in listarTurnos.lista"
                :key="e">
            <q-expansion-item
                dense-toggle
                switch-toggle-side
                :label="e.nombreBase"
            >
                <q-list dense separator >
                    <q-item v-if="e.lista.length == 0">
                        <span> *Sin turnos</span>
                    </q-item>
                    <q-item v-else v-for="i in e.lista" :key="i.nombre">
                        <q-item-section top>
                            <q-item-label lines="1">
                                <span class="text-weight-medium">{{ i.turno+'. '+i.nombre + ' ' + i.apellidos    }} </span>
                            </q-item-label>
                            <q-item-label caption lines="1">
                                Ultima confirmación {{ $filters.timeAprox(i.confirmacion) }}
                            </q-item-label>
                        </q-item-section>
                        <!-- <q-item-section top side>
                            <div class="text-grey-8 q-gutter-xs">
                                <q-btn class="gt-xs" size="12px" @click="deleteDomiBase(i)" flat dense round icon="delete"></q-btn>
                            </div>
                        </q-item-section> -->
                    </q-item>
                    <q-item class="justify-end">
                        <q-btn color="primary" @click="deleteBase(index)">
                            Eliminar
                        </q-btn>
                    </q-item>
                </q-list>
            </q-expansion-item>
        </q-list>
    </div>
    <q-dialog
        :maximized="maximizedToggle"
        transition-show="slide-up"      
        transition-hide="slide-down"        
        persistent
        v-model="openNewBase"
    >   
        <newBase @close-popup-new-base="closePopupNewBase($emit)"></newBase>
    </q-dialog>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import newBase from "./NewBase.vue"
import { host, userUtils } from '@/tools';
import axios from 'axios';
import { useQuasar } from 'quasar';

export default {
    components: {
        newBase,
    },
    setup() {
        const $q = useQuasar();
        const openNewBase = ref(false);
        const listarTurnos = reactive({
            lista: null,
        })

        onMounted(() => {
            callBases()
        });

        function callBases() {
            userUtils.getToken().then((token) => {
                const fd = new FormData();
                fd.append('token', token)
                axios.post(`${host}listarTurnos`, fd).then((rta) => {
                    listarTurnos.lista = rta.data.data

                }).catch((error) =>{ 
                    console.log(error)
                })
            })
        }


        function reload(){
             userUtils.getToken().then((token) => {
                const fd = new FormData();
                fd.append('token', token)
                axios.post(`${host}listarTurnos`, fd).then((rta) => {
                    listarTurnos.lista = rta.data.data
                }).catch((error) =>{ 
                    console.log(error)
                })
            })
        }

        function closePopupNewBase() {
            reload()
            openNewBase.value = false
            
        }

        function deleteBase (a) {
            userUtils.getToken().then((token) => {
                const fd = new FormData();
                fd.append('token', token);
                fd.append('base', a)
                axios.post(`${host}eliminaBase`, fd).then((rta) => {
                    console.log(rta.data.rta)
                    if(rta.data.rta == 'OK')
                        $q.notify({ type: "positive", message: 'Se elimino la base correctamente', timeout: 1500 });
                        reload()
                }).catch((error) => {
                    var msj = error.response.data.rta;
                    $q.notify({ type: "negative", message: msj, timeout: 1500 });
                })
            })
        }
        function deleteDomiBase (a) {
            console.log(a)
            // userUtils.getToken().then((token) => {
            //     const fd = new FormData();
            //     fd.append('token', token);
            //     fd.append('base', a.id)
            //     fd.append('lat', a.lat)
            //     fd.append('lon', a.lon)
            //     fd.append('domiciliarioId', a.domiciliarioId)
            //     axios.post(`${host}/marcaTurno`, fd).then((rta) => {
            //         console.log(rta.data.rta)
            //         if(rta.data.rta == 'OK')
            //             $q.notify({ type: "positive", message: 'Se elimino al domiciliario de la base correctamente', timeout: 1500 });
            //             reload()
            //     }).catch((error) => {
            //         var msj = error.response.data.rta;
            //         $q.notify({ type: "negative", message: msj, timeout: 1500 });
            //     })
            // })
        }

        return {
            openNewBase,
            closePopupNewBase,
            listarTurnos,
            deleteBase,
            deleteDomiBase, 
            callBases
        }
    }
   
}

</script>
