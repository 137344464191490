<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated="0">
      <q-toolbar class="bg-primary">
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />
        <q-toolbar-title> Mensajería Alianza </q-toolbar-title>
        <div>v 2.7.0</div>
      </q-toolbar>
    </q-header>

    <q-drawer
      :width="180"
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2"
    >
      <q-list dense>
        <q-item-label header>Menu principal</q-item-label>
        <q-item
          v-for="i in tabsHome"
          :key="i.name"
          clickable
          @click="goTo(i.goto)"
        >
          <q-icon size="medium" class="q-mr-md" :name="i.img" />
          <q-item-label class="text-weight-regular q-pr-sm">{{
            i.name
          }}</q-item-label>
        </q-item>
        <q-item clickable @click="salir">
          <q-item-section>
            <q-item-label class="text-weight-regular"
              >Cerrar sesión</q-item-label
            >
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
    <q-footer
      v-if="showIndi"
      reveal
      elevated
      class="bg-primary column items-end"
    >
      <q-icon
        @click="showIndi = !showIndi"
        name="close"
        size="xs"
        class="col"
      ></q-icon>
      <q-toolbar>
        <q-toolbar-title>
          <div class="column">
            <span class="text-title">Indicadores del día </span>
            <div class="row q-mb-xs">
              <span class="text-subtitle1 q-mx-md">
                Domicilios lanzados: {{ general.despachados }}</span
              >
              <span class="text-subtitle1 q-mx-md">
                Mensajeros activos: {{ general.mensajerosActivos }}</span
              >
              <span class="text-subtitle1 q-mx-md">
                Domicilios sin tomar: {{ general.domiciliosSinTomar }}</span
              >
              <span class="text-subtitle1 q-mx-md">
                Domicilios en proceso: {{ general.domiciliosActivos }}</span
              >
            </div>
            <div class="row q-mb-md">
              <div class="row">
                <q-btn
                  text-color="black"
                  class="col btn-AD"
                  size="sm"
                  @click="sendNoti"
                  color="secondary"
                  >Alta demanda</q-btn
                >
              </div>
              <div
                class="col q-mx-lg"
                v-if="general.listaDomiciliarios.length != 0"
              >
                <q-select
                  color="white"
                  dark
                  :dense="true"
                  use-input
                  hide-selected
                  fill-input
                  v-model="general.domiciliarioLlamar"
                  @filter="fetchOptionsDomi"
                  @input-value="updateSearchDomi"
                  prepend-icon="search"
                  append-icon="close"
                  input-debounce="400"
                  :options="general.backupDomiciliarios"
                  option-label="nombres"
                  option-value="id"
                  label="Llamar domiciliaríos (Activos del día)"
                >
                  <template v-slot:prepend>
                    <q-icon
                      size="xs"
                      name="refresh"
                      @click="cargueDomis()"
                      class="cursor-pointer"
                    >
                    </q-icon>
                  </template>
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        Sin resultados
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
          </div></q-toolbar-title
        >
      </q-toolbar>
    </q-footer>
    <q-footer v-else class="bg-primary column items-end">
      <q-icon
        @click="showIndi = !showIndi"
        size="xs"
        class="col"
        name="keyboard_arrow_up"
      ></q-icon>
    </q-footer>
  </q-layout>
</template>

<script>
import router from "@/router/index";
import {
  authUtils,
  userUtils,
  host,
  hostDRF,
  configUtils,
  listHome,
} from "@/tools/index.js";
import { onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useQuasar } from "quasar";

export default {
  name: "homePage",

  setup() {
    const intervalId = ref(null);

    const $q = useQuasar();
    // ajustar el time

    const tabsHome = listHome;
    const general = reactive({
      despachados: 0,
      mensajerosActivos: 0,
      dineroRecogido: 0,
      domiciliosSinTomar: 0,
      domiciliosActivos: 0,
      domiciliosViejos: 0,
      domiciliarioLlamar: null,
      backupDomiciliarios: [],
      listaDomiciliarios: [],
    });

    getIndicadores();
    cargueDomis();

    window.onblur = () => {
      clearInterval(intervalId.value);
    };

    window.onfocus = () => {
      getIndicadores();
    };

    onMounted(async () => {
      goTo("registro");
    });

    function cargueDomis() {
      general.listaDomiciliarios = [];
      userUtils.getToken().then((token) => {
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .get(hostDRF + "domiciliarios/?activo=1", {
            headers: headers,
            params: {
              page_size: 1000,
            },
          })
          .then((result) => {
            general.listaDomiciliarios = general.backupDomiciliarios =
              result.data.results;
          })
          .catch((error) => {
            var msj = error.response.data.rta;
            $q.notify({ type: "negative", message: msj, timeout: 2000 });
          });
      });
    }

    // function cargueDomis() {
    //   general.listaDomiciliarios = [];
    //   userUtils.getToken().then((token) => {
    //     const fd = new FormData();
    //     fd.append("token", token);
    //     axios
    //       .post(host + "listaDomiciliarios", fd)
    //       .then((result) => {
    //         if (result.data.rta.length != 0) {
    //           var data = result.data.rta;
    //           for (let d = 0; d < data.length; d++) {
    //             const element = data[d];
    //             if (element["activo"] == true) {
    //               general.listaDomiciliarios.push({
    //                 nombres: element["nombres"] + " " + element["apellidos"],
    //                 id: element["id"],
    //               });
    //             }
    //           }
    //           general.backupDomiciliarios = general.listaDomiciliarios;
    //         }
    //       })
    //       .catch((error) => {
    //         var msj = error.response.data.rta;
    //         $q.notify({ type: "negative", message: msj, timeout: 1500 });
    //       });
    //   });
    // }

    async function getIndicadores() {
      await configUtils
        .getIndicadores()
        .then((rta) => {
          if (intervalId.value) {
            clearInterval(intervalId.value);
          }
          // console.log("traigo la info");
          general.despachados = rta.despachados;
          general.mensajerosActivos = rta.mensajerosActivos;
          general.dineroRecogido = rta.dineroRecogido;
          general.domiciliosSinTomar = rta.domiciliosSinTomar;
          general.domiciliosActivos = rta.domiciliosActivos;
          general.domiciliosViejos = rta.domiciliosViejos;
          intervalId.value = setInterval(() => {
            getIndicadores();
          }, 60000);
        })
        .catch((error) => {
          $q.notify({ type: "negative", message: error.response.data.rta });
        });
    }

    function fetchOptionsDomi(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        general.backupDomiciliarios = general.listaDomiciliarios.filter(
          (v) => v.nombres.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    }

    function updateSearchDomi(a) {
      if (a != "") {
        general.domiciliarioLlamar = a;
        llamarDomi();
      } else {
        general.domiciliarioLlamar = null;
      }
    }
    function llamarDomi() {
      var idDomi = general.backupDomiciliarios.find(
        (element) => element.nombres === general.domiciliarioLlamar
      );
      var splitURL = idDomi.url.split("/");
      var id = splitURL.slice(-2)[0];
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("domiciliarioId", id);
        axios
          .post(host + "alertaCom", fd)
          .then((result) => {
            general.domiciliarioLlamar = null;
            let data = result.data.rta;
            if (data == "OK") {
              $q.notify({
                type: "positive",
                message: "Notificación enviada a: " + idDomi.nombres,
                timeout: 1500,
              });
            }
          })
          .catch((error) => {
            var msj = error.response.data.rta;
            $q.notify({ type: "negative", message: msj, timeout: 1500 });
          });
      });
    }

    function goTo(a) {
      router.push({ name: a });
    }

    function salir() {
      authUtils.out();
      router.push({ name: "login" });
    }

    function sendNoti() {
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("tipo", "ALTA_DEMANDA");
        axios
          .post(host + "envioAlertas", fd)
          .then((result) => {
            if (result.data.rta == "OK") {
              $q.notify({
                type: "positive",
                message: "Alerta de alta demanda enviada",
              });
            } else {
              $q.notify({
                type: "negative",
                message: "Error: no se puedo generar esta alerta",
              });
            }
          })
          .catch((error) => {
            var msj = error.response.data.rta;
            $q.notify({ type: "negative", message: msj, timeout: 1500 });
          });
      });
    }

    return {
      goTo,
      salir,
      sendNoti,
      general,
      leftDrawerOpen: ref(true),
      showIndi: ref(true),
      tabsHome,
      getIndicadores,
      fetchOptionsDomi,
      updateSearchDomi,
      cargueDomis,
    };
  },
};
</script>

<style>
.q-item__section--avatar {
  width: 30px !important;
}
.text-subtitle1 {
  font-size: 12px !important;
}
.text-title {
  font-size: 15px;
}

.btn-AD {
  margin: 0 !important;
}

.select-Llamar {
  padding: 0 !important;
  margin: 0 !important;
}

.hoverSelect:focus {
  color: red !important;
}
</style>
