<template>
  <div class="row">
    <div class="col-3 q-px-md">
      <q-select
        use-input
        hide-selected
        fill-input
        v-model="cartera.domiId"
        @filter="fetchOptionsDomi"
        @input-value="updateSearchDomi"
        :loading="cartera.loading"
        prepend-icon="search"
        append-icon="close"
        input-debounce="400"
        :options="cartera.backupDomiciliarios"
        option-label="nombres"
        option-value="id"
        label="Domiciliario"
      >
        <template v-slot:prepend>
          <q-icon name="refresh" @click="cargueDomis()" class="cursor-pointer">
          </q-icon>
        </template>
        <template v-slot:append>
          <q-icon
            v-if="cartera.domiId !== ''"
            name="close"
            @click="cartera.domiId = null"
            class="cursor-pointer"
          >
          </q-icon>
        </template>
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey"> Sin resultados </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <div class="col-3 q-ma-md">
      <q-input :dense="true" v-model="dateI" mask="date" :rules="['dateI']">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :locale="myLocale"
                color="primary"
                minimal
                v-model="dateI"
              >
                <div class="row items-center justify-end">
                  <q-btn
                    v-close-popup
                    label="Cerrar"
                    color="primary"
                    flat
                  ></q-btn>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <div class="col-3 q-ma-md">
      <q-input :dense="true" v-model="dateF" mask="date" :rules="['dateF']">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :locale="myLocale"
                minimal
                color="primary"
                v-model="dateF"
              >
                <div class="row items-center justify-end">
                  <q-btn
                    v-close-popup
                    label="Cerrar"
                    color="primary"
                    flat
                  ></q-btn>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <div>
      <q-btn @click="cargueCartera()" class="q-mt-md" color="accent">
        Buscar</q-btn
      >
    </div>
  </div>
  <div class="row q-mb-md" v-if="cartera.data.length != 0">
    <span class="text-subtitle1 q-mx-md">
      Domicilios realizados: {{ cartera.data.cantidadServicios }}</span
    >
    <span class="text-subtitle1 q-mx-md">
      Dinero recolectado: {{ cartera.data.dineroRecibidoTotal }}</span
    >
    <span class="text-subtitle1 q-mx-md">
      Horas trabajadas: {{ cartera.data.horasTrabajadas }}</span
    >
    <span class="text-subtitle1 q-mx-md">
      Horas trabajadas prom por día:
      {{ cartera.data.horasTrabajadasPorDia }}</span
    >
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import { onMounted, reactive, ref } from "vue";
import moment from "moment";
import axios from "axios";
import { host, userUtils } from "@/tools/index";

export default {
  setup() {
    const $q = useQuasar();
    const dateI = ref("");
    const dateF = ref("");

    const cartera = reactive({
      data: [],
      isSearch: false,
      listaDomiciliarios: [],
      backupDomiciliarios: [],
      loading: false,
      domiId: null,
    });

    // watch([dateI, dateF, domiId], ([newA, newB, newC]) => {
    //   if (newA != "" && newB != "" && newC != "") {
    //     cargueCartera();
    //   } else {
    //     cartera.data = [];
    //     domiId.value = "";
    //   }
    // });

    onMounted(() => {
      var dat = new Date();
      dateI.value = moment(dat).add(-1, "days").format("YYYY/MM/DD");
      dateF.value = moment(dat).format("YYYY/MM/DD");
      cargueDomis();
    });

    function fetchOptionsDomi(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        cartera.backupDomiciliarios = cartera.listaDomiciliarios.filter(
          (v) => v.nombres.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    }

    function updateSearchDomi(a) {
      if (a != "") {
        cartera.domiId = a;
      } else {
        cartera.domiId = null;
      }
    }

    function cargueCartera() {
      var i = moment(dateI.value).format("YYYY-MM-DD");
      var f = moment(dateF.value).format("YYYY-MM-DD");
      userUtils.getToken().then((token) => {
        cartera.isSearch = true;
        const fd = new FormData();
        fd.append("token", token);
        fd.append("fInicio", i);
        fd.append("fFin", f);
        fd.append("domiciliarioId", cartera.domiId.id);
        axios
          .post(host + "carteraDomiciliario", fd)
          .then((result) => {
            console.log(result);
            cartera.isSearch = false;
            if (result.data["datos"].length != 0) {
              cartera.data = result.data["datos"];
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    function cargueDomis() {
      cartera.listaDomiciliarios = [];
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        axios
          .post(host + "listaDomiciliarios", fd)
          .then((result) => {
            if (result.data.rta.length != 0) {
              var data = result.data.rta;
              for (let d = 0; d < data.length; d++) {
                const element = data[d];
                cartera.listaDomiciliarios.push({
                  nombres: element["nombres"] + " " + element["apellidos"],
                  id: element["id"],
                });
              }
            }
          })
          .catch((error) => {
            $q.notify({ type: "negative", message: error });
          });
      });
    }

    return {
      dateI,
      dateF,
      cartera,
      fetchOptionsDomi,
      updateSearchDomi,
      cargueCartera,
      myLocale: {
        /* starting with Sunday */
        days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
        daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
        months:
          "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
            "_"
          ),
        monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split(
          "_"
        ),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: "dias",
      },
    };
  },
};
</script>

<style>
</style>