<template>
  <q-card class="bg-white text-black">
    <q-bar class="bg-primary">
      <q-btn
        text-color="white"
        v-if="!domicilio.historial"
        flat
        @click="confirm = true"
      >
        Cancelar domicilio</q-btn
      >
      <q-space></q-space>
      <q-btn text-color="white" dense flat icon="close" v-close-popup>
        <q-tooltip class="bg-white text-primary">Cerrar</q-tooltip>
      </q-btn>
    </q-bar>
    <div class="q-mx-xl">
      <q-slider
        class="q-mt-xl"
        v-model="domicilio.status"
        :color="domicilio.statusColor[domicilio.status]"
        markers
        disable
        :marker-labels="domicilio.statusDomi"
        :min="0"
        :max="3"
      ></q-slider>
    </div>

    <div class="row q-ma-lg">
      <div class="col-6 column">
        <span class="text-subtitle1 text-primary col">
          Ajustes de administración:</span
        >
        <div class="row">
          <div class="col-12 q-pr-md">
            <q-select
              :dense="true"
              use-input
              hide-selected
              fill-input
              v-model="domicilio.domiciliario"
              @filter="fetchOptionsDomi"
              @input-value="() => updateSearchDomi(domicilio.domiciliario)"
              prepend-icon="search"
              append-icon="close"
              input-debounce="300"
              :options="domicilio.backupDomiciliarios"
              option-label="nombres"
              option-value="userId"
              label="Domiciliarío actual (Activos actualmente)"
            >
              <!-- <template v-slot:prepend>
              <q-icon
                name="refresh"
                @click="cargueDomis()"
                class="cursor-pointer"
              >
              </q-icon>
            </template> -->
              <template v-slot:after>
                <q-icon
                  name="close"
                  @click="domicilio.domiciliario = null"
                  class="cursor-pointer q-ml-md"
                >
                </q-icon>
              </template>
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Sin resultados
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
            <!-- <q-select
              :dense="true"
              use-input
              v-model="domicilio.domiciliario"
              @filter="fetchOptionsDomi"
              @input-value="updateSearchDomi"
              input-debounce="400"
              :options="domicilio.backupDomiciliarios"
              option-label="nombres"
              option-value="id"
              map-options
              label="Domiciliario actual"
            >
              <template
                v-slot:append
                v-if="
                  domicilio.domiciliario != null || domicilio.domiciliario == ''
                "
              >
                <q-icon
                  name="close"
                  @click="domicilio.domiciliario = null"
                  class="cursor-pointer"
                >
                </q-icon> </template
            ></q-select> -->
          </div>
          <div class="col-12 q-pr-md">
            <q-input
              :dense="true"
              bottom-slots
              v-model="domicilio.precio"
              label="Valor del domicilio"
            >
            </q-input>
          </div>
        </div>
        <span class="text-subtitle1 text-primary col">
          Datos generales del cliente:</span
        >
        <span class="text-subtitle1"> Nombre: {{ info.cliente.nombres }} </span>
        <span class="text-subtitle1">
          Telefono: {{ info.cliente_telefono }}
        </span>
        <span class="text-subtitle1">
          WhatsApp:
          {{
            info.cliente_whatsapp == 0 ? "Sin registro" : info.cliente_whatsapp
          }}
        </span>
        <span class="text-subtitle1" v-if="info.observaciones_admin != 'null'">
          Observaciones Admin: {{ info.observaciones_admin }}
        </span>
        <span class="text-subtitle1" v-if="info.observaciones_admin != 'null'">
          Domicilio de confianza: {{ info.confianza == true ? "SI" : "NO" }}
        </span>
        <span class="text-subtitle1 col">
          Dirección de recogida:
          {{
            info.direccion_recogida[0].ciudad +
            ", " +
            // info.direccion_recogida[0].tipoVia +
            // " " +
            // info.direccion_recogida[0].numVia +
            // " # " +
            // info.direccion_recogida[0].numViaSec +
            // " Indicaciones: " +
            info.direccion_recogida[0].indicaciones
          }}</span
        >
        <span class="text-subtitle1" v-if="info.destino != ''">
          Destino: {{ info.destino }}
        </span>
        <span class="text-subtitle1" v-if="info.zona != ''">
          Zona: {{ info.zona }}
        </span>
        <span class="text-subtitle1 text-primary col">
          Datos generales del domicilio:</span
        >
        <span class="text-subtitle1"> Tipo de domicilio: {{ info.tipo }} </span>
        <span class="text-subtitle1">
          Fecha de envio del domicilio:
          {{ $filters.timeComplete(info.agendacion) }}
        </span>
        <span class="text-subtitle1">
          Fecha de toma del domicilio:
          {{
            info.tomado == null
              ? "Pendiente"
              : $filters.timeComplete(info.tomado)
          }}
        </span>
        <span class="text-subtitle1">
          Fecha de entrega del domicilio:
          {{
            info.finalizado == null
              ? "Pendiente"
              : $filters.timeComplete(info.finalizado)
          }}
        </span>

        <span class="text-subtitle1 text-primary col">
          Soportes del domiciliario:</span
        >
        <!-- <span class="text-subtitle1 text-bold">
          bloqueado: {{ info.pendiente == false ? "NO" : "SI" }}
        </span> -->
        <!-- <span class="text-subtitle1 text-bold" v-if="info.pendiente">
          Motivo: {{ info.comentarioPendiente }}
        </span> -->

        <span class="text-subtitle1 col"
          >Soportes de recogida:
          {{
            info.videos.length == 0 && info.imagenes.length == 0
              ? "Sin registro"
              : ""
          }}</span
        >
        <div v-if="info.videos.length != 0">
          <div class="row" v-for="i in info.videos" :key="i.url">
            <span class="text-subtitle1">
              Hora: {{ $filters.timeComplete(i["timestamp"]) }}
            </span>
            <video-player
              class="col-12"
              :controls="true"
              v-if="i"
              :src="i['url']"
              type="video"
            ></video-player>
          </div>
        </div>

        <div class="row" v-else v-for="k in info.imagenes" :key="k.url">
          <span class="col-12 text-subtitle1">
            Hora: {{ $filters.timeComplete(k["timestamp"]) }}
          </span>
          <a class="col-12" :href="k.url" target="_blank">Ver imagen</a>
        </div>
        <span class="text-subtitle1 col">
          Soportes de entrega:
          {{
            info.videosEntrega.length == 0 && info.imagenesEntrega.length == 0
              ? "Sin registro"
              : ""
          }}
        </span>
        <!-- Si son videos -->
        <div v-if="info.videosEntrega.length != 0">
          <div class="row" v-for="j in info.videosEntrega" :key="j.url">
            <span class="text-subtitle1">
              Hora: {{ $filters.timeComplete(j["timestamp"]) }}
            </span>
            <video-player
              class="col-12"
              :controls="true"
              v-if="j"
              :src="j['url']"
              type="video"
            ></video-player>
          </div>
        </div>
        <div v-else class="row" v-for="l in info.imagenesEntrega" :key="l.url">
          <span class="col-12 text-subtitle1">
            Hora: {{ $filters.timeComplete(l["timestamp"]) }}
          </span>
          <a :href="l.url" class="col-12" target="_blank">Ver imagen</a>
        </div>
        <span class="text-subtitle1 col">
          Observaciones del domiciliario: {{ info.observaciones_domiciliario }}
        </span>
        <span
          v-if="info.direccion_recogida.length != 0"
          class="text-subtitle1 text-primary col"
        >
          Direcciones de entrega:
        </span>
        <div v-if="info.direccion_recogida.length != 0">
          <div class="column" v-for="dir in info.direcciones" :key="dir">
            <span class="text-subtitle1 col">
              {{
                dir["indicaciones"] == "" || dir["nombreDestinatario"] == null
                  ? " Indicacion de entrega: Sin registro"
                  : "Indicacion de entrega: " + dir["indicaciones"]
              }}
            </span>
            <span class="text-subtitle1 col">
              {{
                dir["nombreDestinatario"] == "" ||
                dir["nombreDestinatario"] == null
                  ? " Nombre cliente entrega: Sin registro"
                  : " Nombre cliente entrega: " + dir["nombreDestinatario"]
              }}
            </span>
            <span class="text-subtitle1 col">
              {{
                dir["telDestinatario"] == 0 || dir["nombreDestinatario"] == null
                  ? "Numero cliente entrega: Sin registro"
                  : "Numero cliente entrega: " + dir["telDestinatario"]
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="foobar1" v-if="mapa.mostrarMapa">
          <l-map
            :zoom="mapa.zoom"
            :min-zoom="1"
            :max-zoom="20"
            :center="mapa.posMapaR"
            :options="{ touchZoom: false }"
          >
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ></l-tile-layer>
            <l-control-layers />
            <l-marker
              v-if="mapa.posMapaM.lat != null"
              :lat-lng="mapa.posMapaM"
              :draggable="false"
            >
              <l-icon
                :icon-size="[40, 50]"
                :icon-anchor="[22, 94]"
                :icon-url="require('/src/assets/Icons/bikeOn.png')"
            /></l-marker>

            <l-marker
              v-if="mapa.posMapaR.lat != null"
              :lat-lng="mapa.posMapaR"
              :draggable="false"
            >
              <l-icon
                :icon-size="[25, 25]"
                :icon-anchor="[22, 94]"
                :icon-url="require('/src/assets/Icons/home.png')"
            /></l-marker>
          </l-map>
        </div>
        <div></div>
      </div>
    </div>
    <q-card-actions>
      <q-space></q-space>
      <q-btn
        color="grey"
        @click="$emit('close-popup-new-base', false)"
        flat
        label="Cancelar"
      />
      <q-btn color="primary" @click="editDomiAdmin" flat label="Guardar" />
    </q-card-actions>
  </q-card>
  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="cancel" color="primary" text-color="white"></q-avatar>
        <span class="q-ml-sm">¿Deseas eliminar este domicilio?</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancelar" color="primary" v-close-popup></q-btn>
        <q-btn
          flat
          :label="forzar == false ? 'SI' : 'FORZAR CANCELACION'"
          color="primary"
          @click="cancelarDomi"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import { reactive, ref } from "@vue/reactivity";
import {
  LMap,
  LIcon,
  LMarker,
  LTileLayer,
  LControlLayers,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

import { userUtils, hostDRF, host } from "@/tools/index";
import axios from "axios";
import { useQuasar } from "quasar";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    VideoPlayer,
    LIcon,
    LMap,
    LMarker,
    LTileLayer,
    LControlLayers,
    // LGeoJson,
  },
  name: "DomicilioComponent",
  props: {
    info: Object,
    historial: Boolean,
  },
  emits: ["close-popup-new-base"],

  setup(props, { emit }) {
    const $q = useQuasar();
    const intervalId = ref(undefined);
    const router = useRoute();

    const forzar = ref(false);

    const mapa = reactive({
      posMapaM: [],
      posMapaR: [],
      mostrarMapa: false,
      zoom: 13,
    });

    const domicilio = reactive({
      id: null,
      historial: null,
      domiciliario: null,
      // prioridad: "NO",
      precio: null,
      listaDomiciliarios: [],
      backupDomiciliarios: null,
      status: 0,
      statusDomi: {
        0: "Sin tomar",
        1: "Tomado",
        2: "Recogido",
        3: "Entregado",
      },
      statusColor: ["red-10", "orange-9", "light-green", "blue"],
    });

    onMounted(() => {
      cargueDomis();
      initial();
      getGeo();
    });

    function initial() {
      console.log(props.info);
      domicilio.historial = props.historial;
      domicilio.precio = props.info.precio;
      domicilio.id = props.info.id;

      if (props.info["domiciliario_id"] == null) {
        domicilio.status = 0;
      } else {
        if (props.info["videos"].length == 0 || props.info["imagenes"].length) {
          domicilio.status = 1;
        }
        if (
          props.info["videos"].length != 0 ||
          props.info["imagenes"].length != 0
        ) {
          domicilio.status = 2;
        }
        if (
          (props.info["videosEntrega"].length != 0 ||
            props.info["imagenesEntrega"].length != 0) &&
          props.info["status"] == 2
        ) {
          domicilio.status = 3;
        }
      }
    }

    function getGeo() {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
      // mapa.mostrarMapa = false;
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token), fd.append("domicilioId", props.info.id);
        axios.post(host + "posicionDomicilio", fd).then((result) => {
          if (result.data.rta == "OK") {
            var dat = result.data;
            if (dat.punto?.lat != null) {
              mapa.posMapaM = { lat: dat.punto["lat"], lng: dat.punto["lon"] };
            }
            if (dat.recogida?.lat != null) {
              mapa.posMapaR = {
                lat: dat.recogida["lat"],
                lng: dat.recogida["lon"],
              };
            }
            mapa.mostrarMapa = true;
          }
        });
      });
      var path = router.path;
      if (path === "/domicilios") {
        intervalId.value = setInterval(() => {
          getGeo();
        }, 10000);
      } else {
        clearInterval(intervalId.value);
      }
    }

    function cargueDomis() {
      domicilio.listaDomiciliarios = [];
      userUtils.getToken().then((token) => {
        let headers = {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: token,
        };
        axios
          .get(hostDRF + "domiciliarios/?activo=1", {
            headers: headers,
            params: {
              page_size: 1000,
            },
          })
          .then((result) => {
            // console.log('result DRF')
            // console.log(result)
            domicilio.listaDomiciliarios = domicilio.backupDomiciliarios =
              result.data.results;
            domicilio.domiciliario = !props.info.domiciliario_id
              ? null
              : {
                  nombres:
                    props.info.domiciliario.nombres +
                    " " +
                    props.info.domiciliario.apellidos,
                  id: props.info.domiciliario.id,
                };
          });
      });
      // userUtils.getToken().then((token) => {
      //   const fd = new FormData();
      //   fd.append("token", token);
      //   axios
      //     .post(host + "listaDomiciliarios", fd)
      //     .then((result) => {
      //       if (result.data.rta.length != 0) {
      //         var data = result.data.rta;
      //         for (let d = 0; d < data.length; d++) {
      //           const element = data[d];
      //           if (element["activo"] == true) {
      //             domicilio.listaDomiciliarios.push({
      //               nombres: element["nombres"] + " " + element["apellidos"] ,
      //               id: element["id"],
      //             });
      //           }
      //         }
      //         domicilio.backupDomiciliarios = domicilio.listaDomiciliarios;
      //         domicilio.domiciliario = !props.info.domiciliario_id ? null
      //             :
      //             {
      //                 nombres: props.info.domiciliario.nombres + " " + props.info.domiciliario.apellidos,
      //                 id: props.info.domiciliario.id,
      //             };
      //           console.log(domicilio.domiciliario)
      //       }
      //     })
      //     .catch((error) => {
      //       $q.notify({ type: "negative", message: error.response.data.rta });
      //     });
      // });
    }

    function editDomiAdmin() {
      console.log(domicilio.domiciliario);
      var id;
      if (domicilio.domiciliario) {
        if (domicilio.domiciliario.url) {
          var splitURL = domicilio.domiciliario.url.split("/");
          id = splitURL.slice(-2)[0];
        } else {
          id = domicilio.domiciliario.id;
        }
      }
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("precio", domicilio.precio);
        fd.append("domiciliarioId", domicilio.domiciliario == null ? "" : id);
        fd.append("domiId", domicilio.id);
        axios
          .post(host + "editaDomicilioAdmin", fd)
          .then((result) => {
            if (result.data.rta != "OK") {
              $q.notify({ type: "negative", message: result.data.rta });
            } else {
              emit("close-popup-new-base", false);
              $q.notify({ type: "positive", message: "Domicilio editado" });
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              $q.notify({
                type: "negative",
                message: error.response.data.rta,
                actions: [
                  {
                    label: "Forzar cambio",
                    color: "yellow",
                    timeout: 80000,
                    handler: () => {
                      confirmar();
                    },
                  },
                ],
              });
            } else {
              $q.notify({ type: "negative", message: error.response.data.rta });
            }
          });
      });
    }

    function confirmar() {
      if (domicilio.domiciliario) {
        var splitURL = domicilio.domiciliario.url.split("/");
        var id = splitURL.slice(-2)[0];
      }
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("precio", domicilio.precio);
        fd.append("domiciliarioId", domicilio.domiciliario == null ? "" : id);
        fd.append("domiId", domicilio.id);
        fd.append("forzarCambioDomi", "SI");
        axios
          .post(host + "editaDomicilioAdmin", fd)
          .then((result) => {
            if (result.data.rta != "OK") {
              $q.notify({ type: "negative", message: result.data.rta });
            } else {
              emit("close-popup-new-base", false);
              $q.notify({ type: "positive", message: "Domicilio editado" });
            }
          })
          .catch((error) => {
            $q.notify({ type: "negative", message: error.response.data.rta });
          });
      });
    }

    function cancelarDomi() {
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("domicilioId", domicilio.id);
        fd.append("forzar", forzar.value == true ? "SI" : "NO");
        axios
          .post(host + "cancelarServicio", fd)
          .then((result) => {
            console.log(result);
            if (result.data.rta != "OK") {
              forzar.value = true;
              $q.notify({ type: "negative", message: result.data.rta });
            } else {
              confirm.value = false;
              forzar.value = false;
              emit("close-popup-new-base", false);
            }
          })
          .catch((error) => {
            $q.notify({ type: "negative", message: error.response.data.rta });
          });
      });
    }

    function fetchOptionsDomi(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        domicilio.backupDomiciliarios = domicilio.listaDomiciliarios.filter(
          (v) => v.nombres.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    }

    function updateSearchDomi(a) {
      if (a != "") {
        domicilio.domiciliario = a;
      } else {
        domicilio.domiciliario = null;
      }
    }

    return {
      domicilio,
      mapa,
      close,
      editDomiAdmin,
      cancelarDomi,
      fetchOptionsDomi,
      updateSearchDomi,
      confirm: ref(false),
      forzar,
    };
  },
};
</script>

<style lang="scss" scoped>
.foobar1 {
  /* <--- class we added above */
  width: 100%;
  height: 400px;
}

.video-js {
  min-height: 300px !important;
}

.img_evidencia {
  min-height: 150px !important;
}
</style>
