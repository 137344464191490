<template>
  <div class="column">
    <div class="row">
      <div class="col-3 q-ma-md">
        <q-input :dense="true" v-model="dateI" mask="date" :rules="['dateI']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  :locale="myLocale"
                  color="primary"
                  minimal
                  v-model="dateI"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="primary"
                      flat
                    ></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-3 q-ma-md">
        <q-input :dense="true" v-model="dateF" mask="date" :rules="['dateF']">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  :locale="myLocale"
                  minimal
                  color="primary"
                  v-model="dateF"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Cerrar"
                      color="primary"
                      flat
                    ></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </div>
    <div>
      <template v-if="cobros.suma !== 0">
        <div class="row q-mb-md">
          <div class="col-3">
            <span class="text-subtitle1 text-primary q-mx-md">
              Días del periodo: {{ cobro.diasFiltro }}
            </span>
          </div>
          <div class="col-3">
            <span class="text-subtitle1 text-primary q-mx-md">
              Suma del periodo: {{ $filters.money(cobro.suma) }}
            </span>
          </div>
          <div class="col-3">
            <span class="text-subtitle1 text-primary q-mx-md">
              Domiciliarios activos del periodo:
              {{ cobros.cantidadMicos }}</span
            >
          </div>
          <div class="col-3">
            <span class="text-subtitle1 text-primary q-mx-md">
              Domiciliarios promedio día: {{ cobro.prom }}</span
            >
          </div>
        </div>
      </template>
      <div class="fixed-center" v-if="cobros.isSearch">
        <q-circular-progress
          indeterminate
          size="50px"
          color="orange"
          class="q-ma-md"
        ></q-circular-progress>
      </div>
      <q-table
        flat
        bordered
        rows-per-page-label="Items por pgna"
        v-else
        separator="cell"
        auto-width
        title="Cobros"
        :rows="cobros.listaCobros"
        :columns="columns"
        row-key="nombres"
        :pagination="initialPagination"
        :visible-columns="visibleColumns"
        no-data-label="Sin información"
        :filter-method="myfiltermethod"
        :filter="filter"
      >
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Buscar"
          >
            <template v-slot:append>
              <q-icon name="search"></q-icon>
            </template>
          </q-input>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="fechaiInicial" :props="props">
              {{ $filters.timeComplete(props.row.fechaInicial) }}
            </q-td>
            <q-td key="precio" :props="props">
              {{ $filters.money(props.row.precio) }}
            </q-td>
            <q-td key="pagado" :props="props">
              <q-chip
                clickable
                :color="props.row.pagado ? 'green' : 'orange'"
                text-color="white"
                @click="pagado(props.row)"
                dense
                class="text-weight-bolder"
                square
                >{{ props.row.pagado ? "Pagado" : "Pendiente" }}</q-chip
              >
            </q-td>
            <q-td key="confirmado" :props="props">
              <q-chip
                clickable
                :color="props.row.confirmado ? 'blue' : 'orange'"
                text-color="white"
                @click="confirmado(props.row)"
                dense
                class="text-weight-bolder"
                square
                >{{ props.row.confirmado ? "Confirmado" : "Pendiente" }}</q-chip
              >
            </q-td>
            <q-td key="ver" :props="props">
              <q-btn size="xs" color="accent" @click="verDia(props.row)">
                Ver</q-btn
              >
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <q-dialog
        transition-show="slide-up"
        transition-hide="slide-down"
        v-model="cobros.openDay"
        v-if="cobros.openDay"
      >
        <ver-cobros-dia
          @consultarCobros="cargueCobros()"
          :id="cobro.id"
          :fecha="cobro.dateA"
          :precio="cobro.precio"
        ></ver-cobros-dia>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import { reactive, ref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import moment from "moment";
import { userUtils, host } from "@/tools/index";
import axios from "axios";
import verCobrosDia from "../ChargesByDate.vue";

export default {
  components: {
    verCobrosDia,
  },

  setup() {
    const $q = useQuasar();
    const dateI = ref("");
    const dateF = ref("");
    const cobro = reactive({
      dateA: "",
      precio: 0,
      id: "",
      suma: 0,
      tarifa: 1200,
      prom: 0,
      diasFiltro: 0,
    });

    const columns = ref([
      {
        label: "Fecha",
        name: "fechaiInicial",
        field: "disponible",
        sortable: true,
      },
      {
        label: "Precio",
        name: "precio",
        field: "precio",
        sortable: true,
      },
      {
        label: "Pagado",
        name: "pagado",
        field: "pagado",
        sortable: true,
      },
      {
        label: "Confirmado",
        name: "confirmado",
        field: "confirmado",
        sortable: true,
      },
      {
        label: "Ver",
        name: "ver",
        field: "ver",
        sortable: true,
      },
    ]);

    const cobros = reactive({
      listaCobros: [],
      cantidadMicos: "",
      isSearch: false,
      openDay: false,
    });

    watch([dateI, dateF], ([newA, newB]) => {
      if (newA && newB) {
        cargueCobros();
      }
    });

    onMounted(() => {
      var dat = new Date();
      dateI.value = moment(dat).add(-1, "days").format("YYYY/MM/DD");
      dateF.value = moment(dat).format("YYYY/MM/DD");
    });

    function cargueCobros() {
      cobro.suma = 0;
      cobros.listaCobros = [];
      var i = moment(dateI.value).format("YYYY-MM-DD");
      var f = moment(dateF.value).format("YYYY-MM-DD");
      cobro.diasFiltro = moment(dateF.value).diff(moment(dateI.value), "days");
      userUtils.getToken().then((token) => {
        cobros.isSearch = true;
        const fd = new FormData();
        fd.append("token", token);
        fd.append("tipo", "listar");
        fd.append("fechaInicial", i);
        fd.append("fechaFinal", f);
        axios
          .post(host + "cobros", fd)
          .then((result) => {
            cobros.isSearch = false;
            cobros.cantidadMicos = result.data["cantidadMicos"];
            if (result.data["lista"].length != 0) {
              cobros.listaCobros = result.data["lista"];
              var pay = 0;
              cobros.listaCobros.forEach((k) => (pay = pay + k.precio));
              cobro.suma = pay;
            }
            calculateProm();
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    function calculateProm() {
      if (cobro.suma !== 0)
        cobro.prom = parseInt(cobro.suma / cobro.tarifa / cobro.diasFiltro);
    }

    function pagado(a) {
      userUtils.getToken().then((token) => {
        cobros.isSearch = true;
        const fd = new FormData();
        fd.append("token", token);
        fd.append("tipo", "pagar");
        fd.append("cobroId", a.id);
        axios
          .post(host + "cobros", fd)
          .then((result) => {
            if (result.data.rta == "OK") {
              $q.notify({
                type: "positive",
                message: "Cobro pagado, pendiente de confirmación",
              });
              cargueCobros();
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error.message });
          });
      });
    }

    function confirmado(a) {
      userUtils.getToken().then((token) => {
        const fd = new FormData();
        fd.append("token", token);
        fd.append("tipo", "confirmar");
        fd.append("cobroId", a.id);
        axios
          .post(host + "cobros", fd)
          .then((result) => {
            if (result.data.rta == "OK") {
              $q.notify({ type: "positive", message: "Cobro confirmado" });
              cargueCobros();
            }
          })
          .catch((error) => {
            $q.notify({ type: "warning", message: error });
          });
      });
    }

    function verDia(a) {
      cobro.dateA = a.fechaInicial;
      cobro.precio = a.precio;
      cobro.id = a.id;
      cobros.openDay = true;
    }

    function test() {
      console.log("here");
      cargueCobros();
    }

    return {
      test,
      cargueCobros,
      columns,
      dateI,
      dateF,
      cobro,
      cobros,
      myLocale: {
        /* starting with Sunday */
        days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
        daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
        months:
          "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
            "_"
          ),
        monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split(
          "_"
        ),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: "dias",
      },
      initialPagination: {
        rowsPerPage: cobros.listaCobros.length,
        // rowsNumber: xx if getting data from a server
      },
      pagado,
      confirmado,
      verDia,
    };
  },
};
</script>

<style>
</style>