

const listHome = [
    {  
        name: 'Lanzador de domicilios',
        goto: 'registro',
        img : 'img:/icons/registro.svg'
    },
    {  
        name: 'Gestion de clientes',
        goto: 'addCliente',
        img : 'img:/icons/agregarCliente.svg'
    },
    {  
        name: 'Personal',
        goto: 'equipo',
        img : 'img:/icons/team.svg'
    },
    {  
        name: 'Recurso humano',
        goto: 'recursohumano',
        img : 'img:/icons/personal.svg'
    },
    {  
        name: 'Ver mapa',
        goto: 'mapa',
        img : 'img:/icons/mapa.svg'
    },
    {  
        name: 'Domicilios actuales',
        goto: 'domicilios',
        img : 'img:/icons/domiciliosActuales.svg'
    },
    {  
        name: 'Cartera domiciliarios',
        goto: 'carteraTeam',
        img : 'img:/icons/cartera.svg'
    },
    {  
        name: 'Historial de domicilios',
        goto: 'historialDomicilios',
        img : 'img:/icons/historial.svg'
    },
    {  
        name: 'Configuración general',
        goto: 'configuraciones',
        img : 'img:/icons/configuracion.svg'
    },
    {  
        name: 'Cobros',
        goto: 'cobros',
        img : 'img:/icons/pagos.svg'
    },
]



export  {listHome};
