    
<template>
    <div class="column">
      <span class="text-subtitle1 col"> Configuraciones generales</span>
      <span> * Precionar ENTER para guardar </span>
    </div>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('TIEMPO_MINIMO_FIN', configuracion.tiempoMinFin)"
      outlined
      v-model="configuracion.tiempoMinFin"
      type="number"
      label="Tiempo minimo de finalización (minutos)"
    ></q-input>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('SRVS_CONCURRENTES', configuracion.serviciosConcurrentes)"
      outlined
      v-model="configuracion.serviciosConcurrentes"
      type="number"
      label="No. max de domicilios/ domiciliario todos)"
    ></q-input>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('TIEMPO_ALERTA_SIN_DOMI', configuracion.tiempoAlertaSinDomi)"
      outlined
      v-model="configuracion.tiempoAlertaSinDomi"
      type="number"
      label="Tiempo de alerta servicio sin tomar (minutos)"
    ></q-input>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('DISTANCIA_MIN_ACEPTA', configuracion.distanciaMinAcepta)"
      outlined
      v-model="configuracion.distanciaMinAcepta"
      type="number"
      label="Distancia minima para aceptación de domicilios (metros)"
    ></q-input>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('DISTANCIA_CERCANO', configuracion.distanciaDomiCercano )"
      outlined
      v-model="configuracion.distanciaDomiCercano"
      type="number"
      label="Distancia en metros al domiciliario cercano"
    ></q-input>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('DISTANCIA_BASE', configuracion.distanciaBase)"
      outlined
      v-model="configuracion.distanciaBase"
      type="number"
      label="Distancia en metros a una base para asignación de domicilio"
    ></q-input>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('TIEMPO_PARA_NUEVOS', configuracion.tiempoParaNuevos)"
      outlined
      v-model="configuracion.tiempoParaNuevos"
      type="number"
      label="Segundos en que el servicio esta disponible para nuevos"
    ></q-input>
    <q-input
      class="q-ma-md"
      dense
      @change="editGlobalVariable('MINUTOS_REFRESCA_BASE', configuracion.minutosRefrescaBase)"
      outlined
      v-model="configuracion.minutosRefrescaBase"
      type="number"
      label="Minutos para refrescar en la base"
    ></q-input>
    <draggable-priority></draggable-priority>
</template>

<script>
import {configUtils} from "@/tools/index";
import { reactive } from "@vue/reactivity";
import { useQuasar } from "quasar";

import { onMounted } from "vue";
import DraggablePriority from "./DraggablePriority.vue";


export default {
    setup() {
        const configuracion = reactive({
            tiempoMinFin: 0,
            serviciosConcurrentes: 0,
            tiempoAlertaSinDomi: 0,
            distanciaMinAcepta: 0,
            valorPrioridad: 0,
            distanciaDomiCercano: 0 ,
            distanciaBase: 0 ,
            tiempoParaNuevos: 0,
            minutosRefrescaBase : 0,
        });

        const $q = useQuasar();

        onMounted(async () => {
          await configUtils.getConfigs().then((rta) => {
            configuracion.tiempoMinFin = rta.find(e => e.nombre == "TIEMPO_MINIMO_FIN")["cantidad"];
            configuracion.serviciosConcurrentes = rta.find(e => e.nombre == "SRVS_CONCURRENTES")["cantidad"];
            configuracion.tiempoAlertaSinDomi = rta.find(e => e.nombre == "TIEMPO_ALERTA_SIN_DOMI")["cantidad"];
            configuracion.distanciaMinAcepta = rta.find(e => e.nombre == "DISTANCIA_MIN_ACEPTA")["cantidad"];
            configuracion.distanciaDomiCercano = rta.find(e => e.nombre == "DISTANCIA_CERCANO")["cantidad"];
            configuracion.distanciaBase = rta.find(e => e.nombre == "DISTANCIA_BASE")["cantidad"];
            configuracion.tiempoParaNuevos = rta.find(e => e.nombre == "TIEMPO_PARA_NUEVOS")["cantidad"];
            configuracion.minutosRefrescaBase = rta.find(e => e.nombre == "MINUTOS_REFRESCA_BASE")["cantidad"];
          }).catch((error) => {
            $q.notify({ type: "negative", message: error });
          });
        });

        async function editGlobalVariable(type , cantidad) {
          await configUtils.setVariable(type, cantidad).then((rta) => {
            if (rta) {
              $q.notify({ type: "positive", message: `Se ajustó el indicador ${type} al nuevo valor de ${cantidad}` });
            }
          }).catch((error) => {
            $q.notify({ type: "negative", message: error });
          });
        }

    return { 
          editGlobalVariable,
          configuracion
        };
    },
    components: { DraggablePriority }
};
</script>

<style>
</style>