import homePage from '@/views/homePage.vue';
import loginPage from '@/views/loginPage.vue';
import teamPage from '@/components/Drawer/PageTeam.vue';
import createDomiPage from '@/components/Drawer/PageCreateDelivery.vue'
import managementDelivery from '@/components/Delivery/ManagementDelivery.vue'
// import indicatorsPage from '@/components/indicatorsPage.vue'
import configurationsPage from '@/components/Drawer/PageConfiguration.vue'
import historialDomicilios from '@/components/Drawer/PageHistoryDeliverys.vue'
import cobrosPage from '@/components/Drawer/PagePayments.vue';
import carteraTeam from '@/components/Drawer/PageGeneralInfoDeliverys.vue'
import AgregaCliente from '@/components/Drawer/PageAddClient.vue';
import mapaPage from '@/components/Drawer/PageMap.vue';
import recursosHumanos from "@/components/Drawer/PageHumanResource.vue"

import { createWebHistory, createRouter } from 'vue-router'
import { getAuth } from 'firebase/auth'
// import firebase from 'firebase'

const routes = [
    {
        path: "/",
        name: "login",
        component: loginPage,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: "/home",
        name: "home",
        component: homePage,
        meta: {
            requiresAuth: true,
        },
        children:[
            {
                path: "/registro",
                name: "registro",
                component: createDomiPage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/addCliente",
                name: "addCliente",
                component: AgregaCliente,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/equipo",
                name: "equipo",
                component: teamPage,
                meta: {
                    requiresAuth: true,
                },
            },
             {
                path: "/recursohumano",
                name: "recursohumano",
                component: recursosHumanos,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/mapa",
                name: "mapa",
                component: mapaPage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/domicilios",
                name: "domicilios",
                component: managementDelivery,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/configuraciones",
                name: "configuraciones",
                component: configurationsPage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/historialDomicilios",
                name: "historialDomicilios",
                component: historialDomicilios,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/cobros",
                name: "cobros",
                component: cobrosPage,
                meta: {
                    requiresAuth: true,
                },
            }
            ,
            {
                path: "/carteraTeam",
                name: "carteraTeam",
                component: carteraTeam,
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    getAuth().onAuthStateChanged(function(user) {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        console.log(user, requiresAuth)
        if (!requiresAuth) {
            next()
        } else if (requiresAuth && user) {
            next()
        } else {
            next({name: "login"});
        }
    });
});


export default router
