<template>
  <div class="fixed-center" v-if="!mapa.mostrarMapa">
    <q-circular-progress
      indeterminate
      size="50px"
      color="orange"
      class="q-ma-md"
    ></q-circular-progress>
  </div>
  <div class="row" v-else>
    <div class="col-12">
      <q-list bordered separator>
        <q-item>
          <q-item-section>
            <q-select
              :dense="true"
              use-input
              hide-selected
              fill-input
              v-model="mapa.domi"
               @filter="fetchOptionsDomi"
              @input-value="updateSearchDomi"
              prepend-icon="search"
              append-icon="close"
              input-debounce="300"
              :options="mapa.filter"
              option-label="nombres"
              option-value="id"
              label="Domiciliarios"
            >
            <template v-slot:prepend>
              <q-icon
                name="refresh"
                @click="getGeo()"
                class="cursor-pointer"
              >
              </q-icon>
            </template>
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Sin resultados
                </q-item-section>
              </q-item>
            </template>
          </q-select>
          </q-item-section>
        </q-item>
        <!-- <q-item
          v-if="mapa.domi != ''"
        >
          <q-item-section>
            <q-item-label overline>{{ mapa.domi.nombres }}</q-item-label>
            <q-item-label
              >Ocupado: {{ mapa.domi.enDomi == true ? "SI" : "NO" }}</q-item-label
            >
            <q-item-label caption
              >Minutos sin tomar domicilios: {{ mapa.domi.minutosSinTomar}}</q-item-label
            >
            <q-item-label caption
              >Cantidad domis: {{ mapa.domi.cantidadDomis }}</q-item-label
            >
          </q-item-section>
        </q-item> -->
      </q-list>
    </div>
    <div class="col-12" style="height: 500px;">
      <l-map 
        :zoom="mapa.zoom" 
        :center="mapa.center"
        :min-zoom="1"
        :max-zoom="20"
        :options="{ touchZoom: false }"
        >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        ></l-tile-layer>
        <l-control-layers position="topright"></l-control-layers>
        <l-marker
          v-for="dato in mapa.data"
          :key="dato"
          :lat-lng="dato.position"
          :zIndexOffset="dato.zIndex"
          v-on:click="bringFront(dato)"
        >
        <l-icon
          :icon-anchor="staticAnchor"
          :class-name="dato['enDomi'] == true ? 'l-icon-class-ND' : 'l-icon-class-D' "
        >
          <div class="div-l-icon">
            <span  class="text-l-icon ">
            {{ dato["nombres"] }}
            </span>
          </div>
        </l-icon>
          <l-popup closeButton='true'>
            <div class="column q-ma-xs popup text-l-icon">
              <span > Nombre: {{ dato["nombres"] }}</span>
              <span>
                Tiene domicilios:
                {{ dato["enDomi"] == false ? "No" : "Si" }}</span
              >
              <span>
                Cantidad de domicilios:
                {{ dato["cantidadDomis"] }}</span
              >
              <span>
                Domicilios pendientes:
                {{ dato["domisPendientes"] }}</span
              >
              <span>
                Minutos sin tomar domicilios:
                {{ dato["minutosSinTomar"] }} segundos</span
              >
            </div>
          </l-popup>
        </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script>
import {
  LMap,
  LIcon,
  LMarker,
  LTileLayer,
  LPopup,
  LControlLayers,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

import axios from "axios";
import { host, userUtils } from "@/tools/index";
import { useQuasar } from "quasar";
import { useRoute } from "vue-router";
import { reactive, ref, onMounted } from "vue";

export default {
  components: {
    LMap,
    LIcon,
    LMarker,
    LTileLayer,
    LPopup,
    LControlLayers,
  },
  setup() {
    const intervalId = ref(undefined);
    const router = useRoute();
    
    const $q = useQuasar();
    const mapa = reactive({
      center: [7.121113247309666, -73.11342428633715],
      zoom: 15,
      posMapa: null,
      data: [],
      backData:[],
      filter:[],
      mostrarMapa: false,
      domi: '',
    });

    onMounted(() => {
      getGeo();
    });

  
    // window.onblur = () => {
    //   clearInterval(intervalId.value);
    // };

    // window.onfocus = () => {
    //   getGeo();
    // };

    function fetchOptionsDomi(val, update) {
      if (val != '') {
        update(() => {
          const needle = val.toLowerCase();
          mapa.filter = mapa.data.filter(
            (v) => {
              return v.nombres.toLocaleLowerCase().indexOf(needle) > -1
            }
          );
        });
      }
    }

    function updateSearchDomi(a) {
      if (a != '') {
        mapa.domi = a
        searchPosition()
      } else {
        mapa.domi = ''
        mapa.mostrarMapa = false;
        mapa.center =  [7.121113247309666, -73.11342428633715],
        mapa.filter = mapa.backData
        // mapa.zoom = 12
        mapa.mostrarMapa = true;
      }
    }

    function searchPosition() {
      var data = mapa.backData.find(e => e.nombres == mapa.domi)
      if (data) {
        mapa.data.map(e => e.zIndex = 1)
        mapa.posMapa = data.position;
        mapa.center = data.center;
        data.zIndex = 100
        
      }
    }

    function getGeo() {
      if (intervalId.value) {
        clearInterval(intervalId.value)
      }
      userUtils.getToken().then((token) => {
        
        const fd = new FormData();
        fd.append("token", token);
        axios
          .post(host + "geoMicos", fd)
          .then(result => {
            if (result.data.rta.length != 0) {
              var info = result.data.rta;
              mapa.data = [];
              for (let g = 0; g < info.length; g++) {
                const element = info[g];
                mapa.data.push({
                  id: element["id"],
                  lat: element["lat"],
                  lng: element["lon"],
                  position: { lat: element["lat"], lng: element["lon"] },
                  center: [element["lat"], element["lon"] ],
                  nombres: element["nombre"],
                  enDomi: element["enDomi"],
                  cantidadDomis: element["cantidadDomis"],
                  domisPendientes: element["domisPendientes"],
                  minutosSinTomar: element["minutosSinTomar"],
                  zIndex: 1,
                });
                mapa.backData = mapa.data;
              }
              mapa.mostrarMapa = true;
            }
          })
          .catch((error) => {
            $q.notify({ type: "negative", message: error });
          });
      });
     var path = router.path
      if (path === '/mapa') {
       intervalId.value = setInterval(() => {
          getGeo()
       }, 8000);
     } else {
       clearInterval(intervalId.value)
     }
    }

    function bringFront(element) {
      mapa.data.map(e => e.zIndex = 1)
      element.zIndex = 100
    }


    return {
      mapa,
      updateSearchDomi,
      fetchOptionsDomi,
      staticAnchor: [14, 32],
      bringFront,
    };
  },
};
</script>

<style lang="scss">
.foobar1 {
  min-width: 70%;
  height: 100%;
}

.list {
  min-height: 100% !important;
}

.l-icon-class-D {
  background-color: white !important;
  border: 2px solid rgb(47, 156, 0);
  border-radius: 5px;
  padding: 1px !important;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.l-icon-class-ND {
  background-color: white !important;
  border: 2px solid rgb(228, 0, 0);
  border-radius: 5px;
  padding: 1px !important;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.div-l-icon{
  max-width: 60px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-l-icon{
  font-size: 8px !important;
}

.leaflet-popup-content{
  margin: 2px !important; 
}

.leaflet-popup{
   position: absolute;
   margin-bottom: 50px !important;
}

.leaflet-popup-content-wrapper{
  border-radius: 0px !important
}

</style>
